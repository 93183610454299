@import '../../../../../../../../styles/mixins.scss';

body {
  overflow: visible !important;
}

.modal-accept-policy-block-wrapper {
  @include scrollBar();
  @media only screen and (max-width: 768px) {
    position: absolute !important;
  }
  .MuiDialog-container {
    @media only screen and (max-width: 768px) {
      height: auto;
    }
  }
  .MuiPaper-root {
    width: 100%;
    max-width: 560px;
    @media only screen and (max-width: 768px) {
      margin: 16px;
      max-height: max-content;
    }
  }
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .accept-policy-content {
    padding: 50px 40px 40px 40px;
    position: relative;
    @media only screen and (max-width: 768px) {
      padding: 30px 16px 16px 16px;
    }
    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      @media only screen and (max-width: 768px) {
        top: 14px;
        right: 14px;
      }
    }
    .main-content-wrapper {
      margin-top: 25px;
      @media only screen and (max-width: 768px) {
        margin-top: 16px;
      }
      .title {
        text-align: center;
        @include fontProps('Manrope', 18px, 600, #000000, 25px);
      }
      .main-text {
        margin-top: 10px;
        max-height: 395px;
        overflow: auto;
        @include fontProps('Manrope', 14px, 500, #000000, 24px);
        @media only screen and (max-width: 768px) {
          max-height: 100%;
        }
        p {
          margin-bottom: 10px;
        }
      }
      .sms-verify-bth-wrapper,
      .next-step-bth {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        .sms-verify-bth,
        .next-step-bth {
          @include gradientBth(236px);
          @include fontProps('Manrope', 14px, bold, #404040, 19px);

          @include gradiendBgWithHoverEffect();
        }
      }

      @include scrollBar();
    }
  }
}
