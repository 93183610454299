@import "../../styles/mixins.scss";
.admin-page-wrapper {
  min-height: 100vh;
  display: flex;

  .admin-navigation-wrapper {
    position: fixed;
    height: 100%;
    width: 88px;
    background: linear-gradient(90deg, #ececfe, #fdecf6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 28px 0;
    .navigation-bths-list-wrapper {
      .logo-wrapper {
        display: flex;
        justify-content: center;
      }
      .navigation-bth-item-wrapper {
        width: 50px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        position: relative;

        img {
          width: 24px;
          height: 24px;
        }

        &.active-tab {
          background: linear-gradient(90deg, #d1c9f3, #f1c9e2);
        }

        &.custom-icon {
          img {
            opacity: 0.56;
          }

          &.rotate {
            transform: rotate(180deg);
          }
        }

        img {
          cursor: pointer;
          &:hover {
            transform: scale(1.2);
          }
        }
        .indicator-inbox-list-count {
          border-radius: 50%;
          position: absolute;
          left: 23px;
          top: -51px;
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eb5757;
          @include fontProps("Manrope", 10px, 600, #ffffff, 14px);
        }
      }
      & :nth-child(2) {
        margin-top: 55px;
      }
    }
    .logout-bth-wrapper {
      img {
        cursor: pointer;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .navigation-pannel-wrapper {
    background: #fbf7f8;
    width: 100%;
    padding-top: 28px;
    padding-left: 88px;
  }

  .lang-wrapper {
    position: absolute;
    right: 50px;
    top: 28px;
    @media screen and(max-width: 550px) {
      right: 20px;
    }
    .languages-switcher-bth {
      .lang {
        @include fontProps("Manrope", 24px, 600, #404040, 33px);
      }
    }
    .languages-wrapper {
      left: -17px;
    }
  }
}
