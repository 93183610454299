@import "../../../styles/mixins.scss";

.confirmation-modal-wrapper {
  .additional-text {
    text-align: center;
    margin: 20px 0px;
    @include fontProps("Manrope", 16px, normal, #404040, 20px);
  }

  .textarea-wrapper {
    .textarea-title {
      text-align: center;
      @include fontProps("Manrope", 24px, 600, #404040, 33px);
    }
    textarea {
      width: 100%;
      border: 1px solid #424770;
      border-radius: 8px;
      height: 120px;
      padding: 15px;
      resize: none;
      &:focus {
        outline: none;
        border: 1px solid #000000;
      }
    }
  }

  .MuiPaper-root {
    width: 100%;
    max-width: 360px;
  }

  &.big-modal {
    .MuiPaper-root {
      max-width: 650px;
    }
  }

  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .confirmation-content {
    padding: 50px 35px !important;
    position: relative;

    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    .info-text {
      text-align: center;
      margin-top: 10px;
      @include fontProps("Manrope", 18px, bold, #404040, 25px);
    }
  }

  button:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;

    .dismiss-bths-wrapper {
      margin-right: 4px;
    }

    .dismiss-bths-wrapper,
    .payment-options-wrapper {
      width: 100%;
    }

    .dismiss-request-bth {
      height: 100%;
    }

    .dismiss-bths-wrapper .bth-wrapper .bth {
      padding: 0 !important;
    }

    .bth-wrapper.make-payment-bth-wrapper {
      width: 100%;
    }
  }
}
