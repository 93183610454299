@import '../../../../../styles/mixins.scss';

.download-pdf-bths-wrapper {
  margin-top: 86px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    margin-left: 16px;
    margin-top: 30px;
    flex-direction: column;
  }
  .download-pdf-content {
    @media only screen and (max-width: 768px) {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
    .download-pdf-bth-wrapper {
      display: flex;
      align-items: center;
      margin-left: 34px;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        margin-left: 0;
      }
      &.disabled {
        pointer-events: none;
      }
      img {
        margin-right: 8px;
      }
      .text {
        @include fontProps('Manrope', 14px, 600, #1a203d, 19px);
      }
    }
    .handle-response-wrapper {
      margin-top: 10px;
      margin-left: 34px;
      width: 100%;
      @media only screen and (max-width: 768px) {
        margin-left: 0;
      }
      .loan-contract-generating,
      .error-text {
        font-size: 14px;
        text-align: left;
      }
      .loan-contract-generating {
        animation: blinker 1s linear infinite;
        margin-top: 10px;
        @include fontProps('Manrope', 14px, 500, #404040, 20px);
      }
      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    }
    #dowload-loan-contract-link,
    .pdf-download-link-loan-request {
      width: 0;
      height: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
}
