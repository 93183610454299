@import '../../styles/mixins.scss';

.update-email-page-wrapper {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    #424770;
  min-height: 100vh;
  padding-top: 130px;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    .activation-title {
      @include fontProps('Manrope', 25px, 600, #424770, 30px);
      text-align: center;
    }
    .spinner-wrapper {
      margin-top: 30px;
    }
    .bth-wrapper {
      display: flex;
      justify-content: center;
      .bth {
        width: 100%;
        max-width: 246px;
        padding: 13px;
        border: none;
        background: none;
        background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
          #ff7472;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
          0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin-top: 20px;
        @include fontProps('Manrope', 18px, 600, #ffffff, 25px);
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
        }
      }
    }
  }
}
