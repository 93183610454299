.user-profile-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  .left-auxiliary-bg-block {
    flex: 1;
    // background: aqua;
    background: #f9f2f2;
  }
  .content-container {
    width: 100%;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .right-auxiliary-bg-block {
    flex: 1;
    // background: antiquewhite;
    background-color: #fdfbfc;
  }
}
