@import '../../../../styles/mixins.scss';

.verificate-user-page-wrapper {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    #424770;
  min-height: 100vh;
  padding-bottom: 10px;
  .step-form-wrapper {
    max-width: 1027px;
    // margin: auto;
    margin: 40px auto 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    @media only screen and (max-width: 768px) {
      border-radius: 0;
    }
    .steps-form-header-wrapper {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 40px 40px 25px 40px;
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media only screen and (max-width: 768px) {
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        background: linear-gradient(90deg, #ececfe, #fdecf6);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .step-form-header-item {
        padding: 0;
        &.not-current-step {
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
        .header-item-content {
          display: flex;
          align-items: center;
          padding: 5px;
          .step-text {
            margin-left: 25px;
            .step-order {
              @include fontProps('Manrope', 18px, bold, #424770, 25px);
            }
            .step-title {
              @include fontProps('Manrope', 16px, 300, #000000, 22px);
            }
          }
          .step-img-wrapper {
            width: 48px;
            height: 48px;
            min-width: 48px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.step-fileded {
            .step-img-wrapper {
              background-color: #424770;
              width: 48px;
              height: 48px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          &.step-not-fileded {
            opacity: 0.4;
            .step-img-wrapper {
              background-color: #424770;
            }
          }
        }
      }
      .step-order-counter {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
          @include fontProps('Manrope', 18px, bold, #424770, 22px);
        }
      }
    }
  }
}
