@import './color.scss';

@mixin fontProps(
  $fontFamily,
  $fontSize,
  $fontWeight: 'normal',
  $fontColor: $black,
  $lineHeight: 'normal'
) {
  font-family: $fontFamily;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $fontColor;
  line-height: $lineHeight;
}

@mixin slickDisableDots {
  width: 15px;
  height: 15px;
  background: #dde0e4;
  border-radius: 50%;
  &::before {
    font-size: 0;
  }
}

@mixin slickActiveDots {
  width: 45px;
  height: 15px;
  border-radius: 15px;
  transition: all 0.2s ease;
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    linear-gradient(0deg, #424770, #424770), #f53838;
}

@mixin slickActiveArrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
  z-index: 1;
  transform: translate(0, 0);
  box-shadow: 0px 14px 44px rgba(14, 20, 34, 0.08);
  border: double 3px transparent;
  background-image: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
    linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
    linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@mixin slickDisableArrow {
  background: #ffffff;
  border: double 3px transparent;
  background-image: linear-gradient(#fefefefe, #fefefefe),
    linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
    linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: default;
}

@mixin scrollBar {
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #ececfe, #fdecf6);
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, #ececfe, #fdecf6);
  }
}

@mixin gradientBth($maxWidth) {
  width: 100%;
  max-width: $maxWidth;
  padding: 13px;
  border: none;
  background: none;
  background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  @include fontProps('Manrope', 14px, bold, #ffffff, 19px);
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
}

@mixin questionIconWrapper {
  border-radius: 50%;
  border: 2px solid black;
  width: 20px;
  height: 20px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@mixin switcher() {
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-bottom: 0;
    cursor: auto;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #424770;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #424770;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round.disabled {
    cursor: auto;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@mixin activationFormBthEffect {
  &.active:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
  }
  &.disabled {
    opacity: 0.5;
  }
}

@mixin userProfileTabPannelWrapper(
  $width,
  $maxWidth,
  $height,
  $padding,
  $borderRadius,
  $background
) {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04);
  width: $width;
  max-width: $maxWidth;
  height: $height;
  padding: $padding;
  border-radius: $borderRadius;
  background: $background;
  @media only screen and (max-width: 768px) {
    padding: 0 16px 16px 16px;
    max-width: 100%;
  }
}

@mixin bthGradiendBorderWrapper {
  background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
  border-radius: 8px;
  padding: 2px;
}
@mixin bthWithGradiendBorder {
  border-radius: 5px;
  border: transparent;
  background: #ffffff;
  color: #000000;
  &:hover {
    background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
      #ff7472;
    color: #ffffff;
  }
}
@mixin gradiendBgWithHoverEffect {
  border-radius: 8px;
  color: #ffffff;
  height: 100%;
  background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
    linear-gradient(90deg, #2b3dff -13.54%, #fa403e 108.96%),
    linear-gradient(90deg, #6772e5 -13.54%, #ff7472 108.96%);
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
  }
}

@mixin inputStyling {
  padding: 12px 18px;
  background: rgba(245, 245, 245, 0.3);
  border: 1px solid rgba(200, 200, 200, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &:focus {
    outline: none;
    border: 1px solid #000000;
  }
}

@mixin burgerMenu($spanBg) {
  #burger-menu__wrapper {
    display: none;
    width: 26px;
    height: 24px;
    min-width: 26px;
    position: relative;
    z-index: 8;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $spanBg;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2) {
      top: 10px;
    }
    span:nth-child(3) {
      top: 20px;
      width: 15px;
    }

    &.open span:nth-child(1) {
      top: 10px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    &.open span:nth-child(2) {
      opacity: 0;
      left: -24px;
    }

    &.open span:nth-child(3) {
      top: 10px;
      width: 26px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}

@mixin statusIndicatorWrapper($bgColor) {
  background: $bgColor;
  margin-top: 10px;
  padding: 10px 12px;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    margin-top: 31px;
  }
}

@mixin hoverEffectScale($scaleValue) {
  &:hover {
    transform: scale($scaleValue);
    transition: transform 0.2s ease-in-out;
  }
}

@mixin inputDisabled {
  border: none;
  pointer-events: none;
  background-color: #f4f4f4;
  border-radius: 8px;
}

@mixin inputActive {
  background: rgba(245, 245, 245, 0.3);
  border: 1px solid rgba(200, 200, 200, 0.5);
  padding: 8px;
  border-radius: 4px;
}
