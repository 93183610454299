@import "../../../../../styles/mixins.scss";

header.verification-page-header {
  position: relative;
  top: 0;
  padding-top: 30px;
  z-index: 2;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
    .verification-page-logo__wrapper {
      cursor: pointer;
    }
    .nav__wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-top: 20px;
      }
      .lang-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        .languages-switcher-bth {
          margin-right: 20px;
          .lang {
            @include fontProps("Manrope", 18px, 600, #ffffff, 25px);
            text-transform: uppercase;
            cursor: pointer;
          }
          .tick {
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
      .bth-login__wrapper .bth-login {
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 8px;
        background: none;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        a {
          color: #ffffff;
          padding: 13px 61px;
          display: block;
          @media only screen and (max-width: 768px) {
            padding: 13px 31px;
          }
        }
      }
    }
  }
}
