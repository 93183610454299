@import "../../../../../../styles/mixins.scss";

.modal-account-content {
  .account-details {
    @include fontProps("Manrope", 16px, 600, #1a203d, 27px);
    margin: 30px;
  }

  .account-confirmation-message {
    @include fontProps("Manrope", 16px, 600, #1a203d, 27px);
    text-align: center;
  }
}

.banks-list-content-wrapper {
  @include userProfileTabPannelWrapper(
    100%,
    880px,
    100%,
    35px,
    8px,
    rgba(255, 255, 255, 0.5)
  );
  .banks-list-block-header {
    .header-decription {
      @include fontProps("Manrope", 20px, 600, #1a203d, 27px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 18px, 600, #1a203d, 25px);
        padding: 24px 0;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        text-align: center;
      }
    }
  }
  .banks-list-wrapper {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) {
      margin-top: 0;
      flex-direction: column;
    }
    .bank-item-wrapper {
      width: 100%;
      max-width: 144px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid #424770;
      margin-top: 32px;
      margin-right: 2%;
      height: 150px;
      cursor: pointer;
      text-align: center;
      @media only screen and (max-width: 768px) {
        margin-right: 0;
        max-width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        padding: 24px;
        background: linear-gradient(90deg, #ececfe, #fdecf6);
        flex-direction: column;
        justify-content: center;
        border: none;
        &:first-child {
          margin-top: 24px;
        }
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
      .bank-logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          width: 80%;
        }

        @media only screen and (max-width: 768px) {
          img {
            width: 120px;
          }
        }
      }
      &.active {
        background: #424770;
        .bank-logo-wrapper img {
          // filter: brightness(0) invert(1);
        }
        .bank-name {
          color: #ffffff;
        }
      }
      &:hover {
        transform: scale(1.04);
        transition: transform 0.2s ease-in-out;
      }
      .bank-name {
        margin-top: 12px;
        @media only screen and (max-width: 768px) {
          margin-top: 0;
          // margin-left: 30px;
          text-align: left;
        }
      }
    }
  }
  .bth-bank-details-wrapper {
    margin-top: 40px;
    .bank-details-bth {
      @include gradientBth(231px);
      @include gradiendBgWithHoverEffect();
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow-icon {
        filter: brightness(0) invert(1);
        transform: rotate(180deg);
        margin-left: 10px;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
