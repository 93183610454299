@import '../../../../../styles/mixins.scss';

.loan-history-table-mobile-version-wrapper {
  margin-top: 24px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .loan-history-table-head {
    background: linear-gradient(90deg, #ececfe, #fdecf6);
    .table-head-cell {
      padding: 12px;
      border: none;
      @include fontProps('Manrope', 14px, bold, #424770, 19px);
      &:not(:first-child):not(:last-child) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  .loan-history-table-body {
    .loan-history-table-body-row {
      cursor: pointer;
      .table-body-cell {
        padding: 24px 12px;
        @include fontProps('Manrope', 14px, bold, #424770, 19px);
        &:not(:first-child):not(:last-child) {
          color: #a0a3b7;
          text-align: center;
        }
        &:last-child {
          text-align: right;
        }
      }
      &:last-child {
        .table-body-cell {
          border: none;
        }
      }
      &:hover {
        background: #fafafa;
      }
    }
  }
}
