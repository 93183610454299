// --------
@import "../../../../../styles/mixins.scss";

.loaners-header-wrapper {
  margin-left: 50px;
  @media screen and(max-width: 550px) {
    margin-left: 20px;
  }
  .loaners-title-wrapper {
    @include fontProps("Manrope", 24px, 600, #404040, 33px);
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .loaners-table-filters-wrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;
    @media screen and(max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .loaners-table-filter-item-wrapper {
      position: relative;
      @include fontProps("Manrope", 14px, 600, #404040, 19px);
      .filter-bth-status {
        border-radius: 8px;
        padding: 5px;
        cursor: pointer;
        &:hover {
          background: linear-gradient(90deg, #d1c9f3, #f1c9e2);
          background: linear-gradient(90deg, #d1c9f3, #f1c9e2);
        }
      }
      .checbox-filters-wrapper {
        position: absolute;
        top: 37px;
        background: #ffffff;
        z-index: 2;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
          0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 15px;
        .checbox-filter-item {
          display: flex;
          align-items: center;
          margin: 7px 0;
          input {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
          label {
            width: 100%;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            @include fontProps("Manrope", 14px, 500, #404040, 19px);
            .indicator-wrapper {
              padding: 10px;

              img {
                width: 15px;
              }

              .indicator {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: block;
              }
            }
          }
        }
      }

      &:not(:first-child):not(:last-child) {
        background: #e8e5e9;
        border-radius: 4px;
        margin-left: 10px;
        padding: 6px 21px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
    .search-filter {
      padding-right: 20px;

      .search-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
      .search-input {
        width: 100%;
        padding: 12px 12px 12px 48px;

        border-radius: 90px;
        border: none;
        @include fontProps("Manrope", 14px, 600, #404040, 19px);
        &.disabled {
          background: none;
        }
        &.active {
          background: #ffffff;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
}
