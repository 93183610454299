@import '../../../../styles/mixins.scss';

.profile-settings-tab-wrapper {
  display: flex;
  flex: 1;
  @media only screen and (max-width: 1096px) {
    flex-direction: column;
  }
  .left-block-wrapper {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 40px;
    width: 55%;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
      0px 2px 6px rgba(0, 0, 0, 0.04);
    @media only screen and (max-width: 1096px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 16px 16px 16px;
      box-shadow: none;
    }
    .tab-title {
      @include fontProps('Manrope', 24px, bold, #404040, 33px);
      @media only screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        padding: 24px 0;
        @include fontProps('Manrope', 20px, 600, #424770, 27px);
      }
    }
    .save-bth-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .bth-border-wrapper {
        background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
          #ff7472;
        border-radius: 8px;
        max-width: 165px;
        padding: 1.5px;
        width: 100%;
        text-align: center;
        button {
          width: 100%;
          border-radius: 8px;
          border: none;
          background: #ffffff;
          padding: 5px;
          @include fontProps('Manrope', 14px, 600, #424770, 19px);
        }
      }
    }
  }
  .right-block-wrapper {
    width: 45%;
    padding-left: 30px;
    @media only screen and (max-width: 1096px) {
      width: 100%;
      padding-left: 0;
      margin-top: 20px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 0;
    }
  }
}
