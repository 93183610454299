@import "../../styles/mixins.scss";

.password-recover-page-wrapper {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    #424770;
  min-height: 100vh;
  padding-top: 130px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
  .form__wrapper {
    max-width: 564px;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 25px 12px;
    margin: auto;
    .back-bth-wrapper {
      a {
        @include fontProps("Manrope", 14px, 600, #424770, 19px);
        display: flex;
        align-items: center;
        text-decoration: none;
      }
      .description {
        margin-left: 12px;
      }
    }
    .form-content-wrapper {
      .form-text-wrapper {
        width: 90%;
        margin: auto;
        margin-top: 15px;
        .form-title {
          @include fontProps("Manrope", 27px, 600, #424770, 36px);
          text-align: center;
          @media only screen and (max-width: 768px) {
            font-size: 22px;
          }
        }
        .form-description {
          @include fontProps("Manrope", 20px, 600, #424770, 32px);
          text-align: center;
          margin-top: 15px;
          @media only screen and (max-width: 768px) {
            font-size: 16px;
            font-weight: 28px;
          }
        }
      }
      .form-inputs-wrapper {
        margin-top: 47px;
        max-width: 300px;
        margin: 47px auto auto auto;
        @media only screen and (max-width: 768px) {
          margin-top: 24px;
        }
        .input-group {
          input {
            width: 100%;
            background: rgba(245, 245, 245, 0.3);
            border: 1px solid rgba(200, 200, 200, 0.5);
            box-sizing: border-box;
            border-radius: 8px;
            padding: 5px 12px;
            &:focus {
              outline: none;
            }
          }
          label {
            margin-bottom: 6px;
            @include fontProps("Manrope", 14px, 600, #424770, 19px);
          }
          .invalid-email-info {
            margin-top: 15px;
            @include fontProps("Manrope", 12px, 600, #eb5757, 16px);
          }
        }
        .second-name-group {
          margin-top: 23px;
          .eye-icon__wrapper {
            position: absolute;
            right: 10px;
            top: 30px;
            cursor: pointer;
          }
          input {
            padding-right: 35px;
          }
        }
        .submit-bth {
          @include fontProps("Manrope", 18px, bold, #ffffff, 25px);
          width: 100%;
          padding: 12px;
          margin-top: 34px;
          margin-bottom: 30px;
          border: none;
          @include gradiendBgWithHoverEffect();
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
          @media only screen and (max-width: 768px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
