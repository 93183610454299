@import "../../styles//mixins.scss";

header {
  position: absolute;
  top: 31px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav__wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    nav {
      display: flex;
      align-items: center;
      width: 100%;

      .list__wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        li {
          // margin-right: 52px;
          padding: 0 10px;
          text-align: center;
          &:last-child {
            margin-right: 25px;
          }

          .nav_link {
            @include fontProps("Manrope", 18px, 600, #ffffff, 25px);
            font-family: "Manrope";
            cursor: pointer;
          }
          .lang-wrapper {
            cursor: pointer;
            display: flex;
            align-items: center;
            position: relative;
            .languages-switcher-bth {
              .lang {
                @include fontProps("Manrope", 18px, 600, #ffffff, 25px);
                text-transform: uppercase;
                cursor: pointer;
              }
              .tick {
                cursor: pointer;
                margin-left: 10px;
              }
            }
          }
        }
      }
      @include burgerMenu(#ffffff);
    }
    .bth-login__wrapper .bth-login {
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 8px;
      background: none;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      a {
        color: #ffffff;
        padding: 13px 61px;
        display: block;
      }
    }
  }
}
footer {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.8) -13.54%,
      rgba(255, 64, 154, 0.8) 108.96%
    ),
    linear-gradient(0deg, #424770, #424770);
  padding: 62px 0;
  .footer-content__wrapper {
    display: flex;
    justify-content: space-between;
    .social_networks__wrapper {
      display: flex;
      .social_network__item {
        margin-left: 40px;
      }
    }
  }
}

@media only screen and (max-width: 1140px) {
  header {
    .nav__wrapper {
      justify-content: space-between;
      width: 100%;
      nav {
        .list__wrapper {
          li {
            .nav_link {
              @include fontProps("Manrope", 16px, 600, #ffffff, 25px);
              font-family: "Manrope";
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  header {
    .logo__wrapper {
      display: none;
    }
    .nav__wrapper {
      justify-content: space-between;
      width: 100%;
      nav {
        .list__wrapper {
          position: absolute;
          left: 0;
          background: linear-gradient(
              90deg,
              rgba(43, 61, 255, 0.8) -13.54%,
              rgba(255, 64, 154, 0.8) 108.96%
            ),
            #c4c4c4;
          flex-direction: column;
          padding-left: 0;
          width: 0;
          transition: all 0.7s ease;
          li {
            &:last-child {
              margin-right: 0px;
            }
            padding: 10px 10px 10px 0;
            .nav_link,
            .languages-switcher-bth {
              margin-right: 0;
              white-space: nowrap;
              visibility: hidden;
            }
            .lang-wrapper .languages-wrapper {
              top: calc(100%);
            }
            &:first-child {
              padding-top: 215px;
            }
          }
        }
        .list__wrapper.open {
          width: 100%;
          padding-left: 21px;
          padding-bottom: 30px;
          visibility: visible;
          li .nav_link,
          li .languages-switcher-bth {
            visibility: visible;
          }
        }
      }
    }
  }

  footer {
    background: linear-gradient(
        90deg,
        rgba(43, 61, 255, 0.8) -13.54%,
        rgba(255, 64, 154, 0.8) 108.96%
      ),
      linear-gradient(0deg, #424770, #424770);
    padding: 46px 0;
    .footer-content__wrapper {
      flex-direction: column;
      align-items: center;
      .social_networks__wrapper {
        display: flex;
        justify-content: space-between;
        width: 150px;
        margin-top: 50px;
        .social_network__item {
          margin-left: 0px;
        }
      }
    }
  }
}
