@import "../../../styles/mixins.scss";

.button-wrapper {
  position: fixed;
  width: 45px;
  z-index: 1;
  bottom: 2vh;
  right: 10px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
  }
}
