@import "../../../styles//mixins.scss";

.top-banner-content__calculator__content {
  @media only screen and (max-width: 768px) {
    padding: 15px 33px;
  }
  .top-banner-content__calculator {
    @media only screen and (max-width: 768px) {
      width: 100%;
      border-radius: 0;
      margin-top: 24px;
    }
  }
  .top-banner-content__calculator__item__wrapper {
    .top-banner-content__calculator__item__desription {
      display: flex;
      justify-content: space-between;
    }
    .top-banner-content__calculator__item__desription-text {
      text-align: left;
    }
    .top-banner-content__calculator__item__slider__wrapper {
      margin-top: 29px;
    }
    .top-banner-content__calculator__item__desription-value {
      opacity: 0.8;
      border: 1px solid #c8c8c8;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      padding: 7px;
      width: 100%;
      max-width: 90px;
    }
    .top-banner-content__calculator__item-sum {
      text-align: right;
    }
    .top-banner-content__calculator__item-date {
      margin-top: 40px;
      text-align: center;
    }
  }
  .top-banner-content__calculator__bth__wrapper {
    margin-top: 28px;
  }
  .top-banner-content__calculator__bth__wrapper button {
    background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
      #ff7472;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 13px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    border: transparent;
    width: 100%;
  }
  .top-banner-content__calculator__report__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;
  }
  .top-banner-content__calculator__report__wrapper {
    .top-banner-content__calculator__report__item {
      width: 50%;
      margin-bottom: 21px;
      @media only screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .top-banner-content__calculator__report__item__value {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        margin-top: 4px;

        span {
          &:first-child {
            margin-right: 10px;
            font-size: 16px;
            text-decoration: line-through;
            color: #000000;
            opacity: 0.3;
          }
        }
      }
    }
    .top-banner-content__calculator__report__item.amount-sum {
      .top-banner-content__calculator__report__item__description {
        display: flex;
      }
      .questionIcon__wrapper {
        margin-left: 9px;
        position: relative;
        @include questionIconWrapper();

        .questionInfo {
          position: absolute;
          visibility: hidden;
          width: 300px;
          background: #ffffff;
          box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
          left: -63px;
          text-align: center;
          border-radius: 6px;
          padding: 14px;
          position: absolute;
          bottom: 40px;
          margin-left: -60px;
          text-align: left;
          span {
            display: flex;
            justify-items: left;
          }

          .concept-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(:first-child) {
              margin-top: 5px;
            }

            &:not(:last-child) {
              border-bottom: 1px solid #cdcdcd;
              margin-bottom: 5px;
            }

            span {
              &:first-child {
                max-width: 75%;
              }
            }

            &-discounted {
              display: flex;
              align-items: center;

              span {
                &:first-child {
                  margin-right: 10px;
                  font-size: 14px;
                  text-decoration: line-through;
                  color: #000000;
                  opacity: 0.3;
                }
              }
            }
          }
        }

        .questionInfo::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 13px;
          border-style: solid;
          border-color: #ffffff transparent transparent transparent;
          text-align: left;
        }
      }

      .questionIcon__wrapper:hover .questionInfo {
        visibility: visible;
      }
      .top-banner-content__calculator__report__item__additional-info {
        margin-top: 4px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        opacity: 0.6;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .amount-return-on-all-steps-сalculation-wrapper {
    margin: 15px 0;

    .drop-down-item,
    .drop-down-сalculation-item {
      display: flex;
      align-items: center;
      .form-control {
        margin-left: 24px;
        width: 100%;
        max-width: 120px;
      }
      .MuiSelect-select {
        padding-left: 10px;
      }
      .title {
        @include fontProps("Roboto", 16px, normal, #000000, 24px);
      }
      .value {
        @include fontProps("Roboto", 16px, 600, #000000, 24px);
        margin-left: 10px;
      }
    }
    .drop-down-сalculation-item {
      margin-top: 20px;
    }
  }
  .top-banner-content__calculator__discounts__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
    .top-banner-content__calculator__description {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #424770;
      border-bottom: 1px dashed #424770;
      margin-right: 11%;
    }
    .wrapper {
      display: flex;
      margin-top: 5px;
      @media only screen and (max-width: 768px) {
        display: flex;
        margin-left: 0;
        width: 100%;
        justify-content: space-between;
      }
    }
    .top-banner-content__calculator__input__promocode {
      opacity: 0.8;
      border: 1px solid #c8c8c8;
      border-radius: 8px;
      padding: 13px 16px;
      width: 128px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
    .top-banner-content__calculator__input__bth-apply-promocode__wrapper {
      margin-left: 12px;
      @include bthGradiendBorderWrapper();
      &.disabled {
        opacity: 0.5;
        cursor: auto;
      }
      &.active {
        button {
          @include bthWithGradiendBorder();
          cursor: pointer;
        }
      }
      button {
        border-radius: 8px;
        border: transparent;
        background: #ffffff;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        width: 128px;
        padding: 13px 16px;
      }
    }
    .handle-response-wrapper {
      margin-top: 10px;
      width: 100%;
      .checking-promocode-spinner {
        animation: blinker 1s linear infinite;
        @include fontProps("Manrope", 14px, 500, #424770, 20px);
      }
      .error-wrapper {
        .error-text {
          font-size: 14px;
        }
      }
      .promocode-info {
        @include fontProps("Manrope", 14px, 600, #eb5757, 20px);
      }
      .success-info {
        @include fontProps("Manrope", 14px, 600, #219653, 20px);
      }
      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    }
  }
  .top-banner-content__calculator__help-info {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #424770;
    border-bottom: 1px dashed #424770;
    margin-top: 37px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    .tooltiptext {
      @include fontProps("Roboto", 16px, normal, #000000, 24px);
      position: absolute;
      visibility: hidden;
      width: 287px;
      background: #ffffff;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

      text-align: center;
      border-radius: 6px;
      padding: 24px;
      position: absolute;
      bottom: 190%;
      margin-left: -60px;
    }
    .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 35%;
      margin-left: -5px;
      border-width: 13px;
      border-style: solid;
      border-color: #ffffff transparent transparent transparent;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      border-bottom: none;
      opacity: 1;
      .tooltiptext {
        margin-left: -16px;
      }
    }
  }
  .top-banner-content__calculator__help-info:hover .tooltiptext {
    visibility: visible;
  }
}
