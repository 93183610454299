@import "../../../styles/mixins.scss";

body {
  overflow: visible !important;
}

.modal-reputation-item-wrapper {
  position: absolute !important;
  background: none;

  input.input-item,
  select,
  textarea,
  .MuiFormControl-root {
    width: 100%;
    max-width: 232px;
    padding: 10px 15px;
    background: rgba(245, 245, 245, 0.3);
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 8px;
    @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
    .MuiFilledInput-root {
      background: transparent;
    }
    .MuiSelect-root {
      padding: 0;
    }
    .MuiInputBase-root::before {
      border: none;
    }
    &:focus {
      outline: none;
      border: 2px solid #696565;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      margin-top: 6px;
    }
  }

  .MuiPaper-root {
    width: 100%;
    max-width: 1000px;
    max-height: max-content;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .MuiDialog-paper {
    margin: 10px;
  }
  .MuiDialog-container {
    // height: auto;
  }

  .reference-title {
    padding-right: 10px;
    @include fontProps("Manrope", 16px, 600, #404040, 19px);
    margin-bottom: 15px;
  }

  .loan-department-form-control {
    min-width: 200px;
  }

  .reputation-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .reputation-item-content {
    padding: 0 0 60px 0 !important;

    .MuiInputBase-input {
      padding: 0;
    }

    .description-value {
      display: flex;

      div:first-child {
        margin-right: 10px;
      }
    }

    &.loading-content {
      height: 97vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .reputation-item-header-wrapper {
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      display: flex;
      // justify-content: space-between;
      padding: 20px 60px;

      @media screen and(max-width: 900px) {
        padding: 10px 20px;
        flex-direction: column;
      }

      .modal-title {
        @include fontProps("Manrope", 20px, 600, #404040, 33px);
      }

      .user-name-container {
        display: flex;
        width: 50%;
        justify-content: space-between;
        align-items: center;

        .user-name {
          @include fontProps("Manrope", 24px, 600, #404040, 33px);
        }

        .reputation {
          position: absolute;
          left: 10px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          display: flex;
          @include fontProps("Manrope", 20px, 600, #ffffff);
        }
      }
      .reputation-item-header-navigation {
        display: flex;
        align-items: center;
        position: absolute;
        right: 20px;

        @media screen and(max-width: 450px) {
          margin-right: 0px;
          order: -1;
          justify-content: space-between;
          margin-bottom: 10px;
        }
        .switch-item-icons-wrapper {
          display: flex;
          margin-right: 50px;
          @media screen and(max-width: 450px) {
            margin-right: 0px;
          }
          .switch-item-bth {
            &.prev-item {
              margin-right: 40px;
            }
            &.next-item {
              transform: rotate(180deg);
            }
            &.disabled {
              opacity: 0.2;
              pointer-events: none;
            }
            &.active {
              cursor: pointer;
            }
          }
        }
        .close-img-wrapper {
          cursor: pointer;
        }
      }
    }
    .spinner-wrapper,
    .error-wrapper,
    .success-wrapper {
      margin-top: 50px;
      .spinner-text {
        font-size: 13px;
      }
    }
    .main-content-wrapper {
      margin-top: 17px;
      display: flex;
      padding: 0 60px;
      flex-direction: column;

      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }

      .reputation-changer {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        padding: 20px 0;

        .reputation-changer-title {
          margin-right: 30px;
        }

        .reputation-list {
          display: flex;

          .reputation-item {
            width: 30px;
            height: 30px;
            border-radius: 50%;

            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
      }
    }
    .loan-content-wrapper {
      padding: 10px 60px;
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 10px 20px;
      }
      border-bottom: 1px solid grey;
      .loan-item-sub-info {
        display: flex;
        align-items: center;

        .title {
          margin-right: 10px;
        }
      }
      .loan-item-info-wrapper {
        padding: 15px 0;
        display: flex;
        align-items: center;
        @media screen and(max-width: 450px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .title {
          width: 150px;
          @include fontProps("Manrope", 14px, bold, #000000, 19px);
        }
        .value {
          @include fontProps("Manrope", 14px, 600, #404040, 19px);
          width: 80%;
        }
      }
    }
    .reputation-content-wrapper {
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }
      .reputation-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .reputation-table-title {
          @include fontProps("Manrope", 20px, 600, #404040, 27px);
        }
        .bth-border-wrapper {
          background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
            #ff7472;
          padding: 2px;
          border-radius: 8px;
          .bth {
            cursor: pointer;
            padding: 10px 20px;
            background: linear-gradient(90deg, #d1c9f8, #efcaea);
            border-radius: 8px;
            @include fontProps("Manrope", 14px, bold, #424770, 19px);
          }
          &.bth-border-block-loaner-wrapper {
            margin-left: 15px;
            display: flex;
            align-items: center;
            .icon {
              margin-right: 10px;
              width: 14px;
            }
          }
        }
      }

      .table-container {
        margin-top: 30px;
        height: 300px;
        overflow-y: auto;

        .reputation-table-head {
          background: linear-gradient(90deg, #ececfe, #fdecf6);
          .table-head-cell {
            border-bottom: none;
            padding: 11px 20px;
            @include fontProps("Manrope", 14px, bold, #404040, 19px);
          }
        }
        .reputation-table-body {
          td:first-child:not(.reputation-notification) {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .reputation-notification {
            margin-top: 15px;
            text-align: center;
            @include fontProps("Manrope", 14px, 500, #404040, 18px);
          }
          .reputation-body-row {
            cursor: pointer;
            .table-body-cell {
              padding: 20px 16px;
              @include fontProps("Manrope", 13px, 500, #404040, 18px);
            }
            &:hover {
              background: #fafafa;
            }
          }
        }
      }
    }

    .editor-wrapper {
      margin-top: 20px;
      border-top: 1px solid grey;

      .title {
        font-family: "Manrope";
        font-weight: bold;
        font-size: 14px;
        color: #000000;
        line-height: 19px;
        padding-left: 60px;
        margin-top: 20px;
      }

      .editor-container {
        margin: 10px 60px auto;
        border: 1px solid rgba(200, 200, 200, 0.5);

        .editorClassName {
          padding: 0 20px;
          height: 300px;
        }

        .toolbarClassName {
          border: none;
          border-bottom: 1px solid #f1f1f1;
        }
      }
    }

    .bths-actions-wrapper {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      @media screen and(max-width: 790px) {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
      }
      .bth-action-wrapper {
        @media screen and(max-width: 790px) {
          margin-top: 10px;
        }
        .bth {
          background: none;
          border: none;
          padding: 10px 50px;
          border-radius: 8px;
          @media screen and(max-width: 790px) {
            width: 250px;
          }
          @include fontProps("Manrope", 14px, 600, #ffffff, 18px);
          &.bth-photo-not-match {
            color: #000000;
            background: #e8e5e9;
          }
          &.bth-deny {
            background: #eb5757;
          }
          &.bth-accept {
            background: #219653;
          }
          &.bth-block {
            background: #000000;
          }
          &.bth-accept-no-decision {
            background: #ffbf00;
            color: black;
          }
        }
        &:not(:last-child) {
          margin-right: 16px;
          @media screen and(max-width: 790px) {
            margin-right: 0px;
          }
        }
      }

      button:disabled {
        opacity: 0.5;
      }
    }
  }
  @include scrollBar();
}
