@import "../../../../../styles/mixins.scss";

.contracts-table-wrapper {
  background: #ffffff;
  flex: 1;
  margin-top: 25px;
  overflow-y: auto;

  .spinner-wrapper {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .error-wrapper {
    margin-top: 50px;
  }

  .table-container {
    min-height: 70vh;

    th {
      min-width: 110px;
    }

    .MuiTable-root {
      min-width: 1000px;
    }
    .contracts-table-head {
      .table-head-cell {
        border-bottom: none;
        padding: 25px 16px;
        @include fontProps("Manrope", 14px, bold, #404040, 19px);
        .sort-icon {
          margin-left: 10px;
        }
        &:first-child {
          padding-left: 50px;
          padding-right: 0;
          @media screen and(max-width: 550px) {
            padding-left: 20px;
          }
        }
        &:nth-child(2) {
          padding: 25px 0;
        }
      }
    }
    .contracts-table-body {
      .contracts-empty {
        margin-top: 10px;
        padding-left: 10px;
        @include fontProps("Manrope", 14px, 500, #404040, 20px);
      }
      .contracts-table-body-row {
        cursor: pointer;

        .table-body-cell {
          padding: 20px 16px;
          @include fontProps("Manrope", 13px, 500, #404040, 18px);
          input {
            width: 20px;
            height: 20px;
          }
          &:first-child {
            padding-left: 50px;
            padding-right: 30px;
            width: 10px;
            @media screen and(max-width: 550px) {
              padding-left: 20px;
            }
          }
          &:nth-child(2) {
            padding: 25px 0;
          }
          .indicator {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: block;
          }

          &.loan-refinanced-si {
            color: #eb5757;
          }
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
  @include scrollBar();
}
