@import '../../../../../styles/mixins.scss';

.balance-header-wrapper {
  margin-left: 50px;
  @media screen and(max-width: 550px) {
    margin-left: 20px;
  }
  .balance-title-wrapper {
    @include fontProps('Manrope', 24px, 600, #404040, 33px);
  }
}
