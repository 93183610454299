@import "../../../styles/mixins.scss";

.header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media only screen and (max-width: 768px) {
    background: #f9f2f2;
  }
  .burger-menu__content {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    @include burgerMenu(#424770);
    padding: 32px 0 32px 21px;
  }
  .logo-wrapper {
    height: 100%;
    width: 250px;
    background: #f9f2f2;
    padding: 32px 21px 0 21px;
    @media only screen and (max-width: 400px) {
      width: auto;
      padding: 32px 0 32px 0;

      img {
        width: 80px;
      }
    }
  }
  .header-navigation-wrapper {
    display: flex;
    flex: 1;
    padding: 32px 21px 0 21px;
    background-color: #fdfbfc;
    @media only screen and (max-width: 768px) {
      padding: 32px 21px 32px 0;
      flex: 0;
      background-color: #f9f2f2;
    }
    &.s-b {
      justify-content: space-between;
    }
    &.f-e {
      justify-content: flex-end;
    }
  }
  .back-bth-wrapper {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    span {
      margin-left: 10px;
      @include fontProps("Manrope", 16px, 600, #424770, 22px);
    }
    &:hover {
      transform: scale(1.2);
      transition: transform 0.2s ease-in-out;
    }
  }
  .left-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .lang-wrapper {
      margin-right: 30px;
      @include fontProps("Manrope", 16px, 600, #424770, 22px);
      text-transform: uppercase;
      @media only screen and (max-width: 768px) {
        margin-right: 15px;
      }
      .languages-switcher-bth {
        display: flex;
        align-items: center;
        .tick {
          margin-left: 5px;
          filter: invert(28%) sepia(10%) saturate(2334%) hue-rotate(196deg)
            brightness(91%) contrast(88%);
        }
      }
    }
    .user-header-info-wrapper {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .notifications-icon-wrapper {
      padding: 6px 12px;
      cursor: pointer;
      position: relative;
      background: #9367c2;
      mix-blend-mode: normal;
      opacity: 0.8;
      box-shadow: 0px 5px 30px rgba(186, 19, 88, 0.2);
      border-radius: 14px;
      display: flex;
      &:hover {
        transform: scale(1.2);
        transition: transform 0.2s ease-in-out;
      }
      .notification-count {
        margin-left: 10px;
        @include fontProps("Manrope", 12px, 600, #ffffff, 16px);
      }
    }
  }
}
