@import "../../../styles/mixins.scss";

.login-page-wrapper {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    #424770;
  min-height: 100vh;
  padding-top: 130px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
  .form__wrapper {
    max-width: 564px;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 54px 12px;
    margin: auto;
    @media only screen and (max-width: 768px) {
      padding: 24px 12px;
    }
    .form-content-wrapper {
      max-width: 300px;
      margin: auto;
      .form-title {
        @include fontProps("Manrope", 27px, 600, #424770, 36px);
        text-align: center;
        @media only screen and (max-width: 768px) {
          font-size: 22px;
        }
      }
      .form-inputs-wrapper {
        margin-top: 47px;
        @media only screen and (max-width: 768px) {
          margin-top: 24px;
        }
        .input-group {
          input {
            width: 100%;
            background: rgba(245, 245, 245, 0.3);
            border: 1px solid rgba(200, 200, 200, 0.5);
            box-sizing: border-box;
            border-radius: 8px;
            padding: 5px 12px;
            &:focus {
              outline: none;
            }
          }
          .invalid-email-info {
            margin-top: 5px;
            @include fontProps("Manrope", 12px, 600, #eb5757, 16px);
          }
          label {
            margin-bottom: 6px;
            @include fontProps("Manrope", 14px, 600, #424770, 19px);
          }
        }
        .password-group {
          margin-top: 23px;
          .eye-icon__wrapper {
            position: absolute;
            right: 10px;
            top: 30px;
            cursor: pointer;
          }
          input {
            padding-right: 35px;
          }
          .eye-icon:after {
            content: "/";
            color: red;
            font-weight: 700;
            font-size: 1.7em;
            left: 7px;
            top: -10px;
            display: block;
          }
        }
        .login-pending {
          margin-top: 10px;
          @include fontProps("Manrope", 14px, normal, #424770, 19px);
          text-align: center;
          animation: blinker 1s linear infinite;
        }
        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
        .submit-bth {
          @include fontProps("Manrope", 18px, bold, #ffffff, 25px);
          @include gradiendBgWithHoverEffect();
          border: none;
          width: 100%;
          padding: 12px;
          margin-top: 34px;
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
        .register-link-wrapper {
          text-align: center;
          margin-top: 24px;
          a {
            @include fontProps("Manrope", 14px, normal, #424770, 19px);
            text-decoration: none;
          }
        }
        .forgot-password-bth__wrapper {
          text-align: center;
          margin-top: 24px;
          a {
            @include fontProps("Manrope", 14px, normal, #424770, 19px);
            text-decoration: none;
          }
        }
        .facebook-auth-bth__wrapper {
          margin-top: 42px;
          @media only screen and (max-width: 768px) {
            margin-top: 30px;
          }
          .kep-login-facebook {
            background: #f4f4f4;
            box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
              0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            width: 100%;
            border: none;
            padding: 12px;
            text-transform: none;
            @include fontProps("Manrope", 18px, normal, #424770, 25px);
          }
        }
      }
    }
  }
}
