@import "../../../../../styles/mixins.scss";

.modal-header-component {
  .modal-header-title {
    text-align: center;
    @include fontProps("Manrope", 18px, bold, #404040, 19px);
    margin-bottom: 15px;
  }

  .reputation-item-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .confirmation-reputation-item {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .modal-header-subtitle {
    text-align: center;
    @include fontProps("Manrope", 16px, bold, #404040, 19px);
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .final-amount {
    @include fontProps("Manrope", 16px, bold, #404040, 18px);
    padding: 30px;
    text-align: center;
  }

  .textarea-content {
    display: flex;
    flex-direction: column;
    @include fontProps("Manrope", 14px, bold, #404040, 16px);

    span {
      @include fontProps("Manrope", 16px, bold, #404040, 18px);
      margin-bottom: 15px;
      text-align: center;
    }

    textarea {
      height: 100px;
      padding: 10px 15px;
      background: rgba(245, 245, 245, 0.3);
      border: 1px solid rgba(200, 200, 200, 0.5);
      border-radius: 8px;
      @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
    }
  }

  .payments-container {
    display: flex;
    justify-content: center;

    .payment-item {
      &:first-child {
        margin-right: 20px;
      }

      &-title {
        @include fontProps("Manrope", 14px, bold, #404040, 19px);
        margin-bottom: 10px;
        margin-left: 10px;
      }

      .payment-info {
        border: 1px solid #404040;
        padding: 15px;
        border-radius: 10px;
        margin-top: 10px;

        &-item {
          display: flex;
          @include fontProps("Manrope", 14px, normal, #404040, 19px);

          &:not(:last-child) {
            margin-bottom: 10px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
          }

          div:first-child {
            width: 120px;
            @include fontProps("Manrope", 14px, bold, #000000, 19px);
          }
        }
      }
    }
  }
}

.conciliation-content-wrapper {
  max-width: 1250px;

  .radio-item {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 0;
  }

  .main-title {
    padding: 10px;
    margin: 0 10px;
    @include fontProps("Manrope", 17px, bold, #404040, 19px);
  }

  .operations-container {
    display: flex;
    padding: 10px;
    margin: 10px;

    .operations-title {
      @include fontProps("Manrope", 17px, bold, #404040, 19px);
      margin-bottom: 15px;
    }

    .operations-item {
      &:first-child {
        margin-right: 15px;
        flex: 1;
      }
    }

    .operations-list {
      border: 1px solid #404040;
      border-radius: 10px;
      height: 500px;
      overflow-y: auto;

      .name-cell {
        max-width: 150px;
      }
    }
  }

  .filter-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .react-datepicker__close-icon::after {
      background-color: #eb5757;
    }

    .input-date-container {
      position: relative;

      .input-calendar {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 5px;
      }

      img {
        cursor: pointer;
      }
    }
  }

  .input-actions {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    &.emergency {
      justify-content: space-evenly;
      margin-top: 10px;

      .payment-bths-wrapper {
        .bth-wrapper {
          max-width: 220px;
        }
      }
    }

    .payment-bths-wrapper {
      margin-bottom: 2px;

      &.check-button {
        margin: 0;

        .make-payment-bth {
          margin-left: 70px;
          width: 200px;
        }
      }

      .bth-wrapper {
        width: 100%;
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-right: 15px;
      @include fontProps("Manrope", 14px, bold, #404040, 19px);

      span {
        margin-bottom: 10px;
      }
    }
  }

  button:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }

  .input-item {
    width: 250px;
    padding: 10px 15px;
    background: rgba(245, 245, 245, 0.3);
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 8px;
    font-family: "Manrope";
    font-weight: 600;
    font-size: 14px;
    color: #1a203d;
    line-height: 20px;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
}
