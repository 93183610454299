@import "../../../../../../styles//mixins.scss";

body {
  overflow: visible !important;
}

.modal-loaners-item-wrapper {
  position: absolute !important;
  background: none;

  .MuiPaper-root {
    width: 100%;
    max-width: 1094px;
    max-height: max-content;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .MuiDialog-container {
    height: auto;
  }
  .MuiDialog-paper {
    margin: 10px;
  }

  .MuiTable-root {
    min-width: 700px;
  }

  .loaners-item-content {
    padding: 0 0 60px 0 !important;

    .loading-content {
      .spinner-wrapper {
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .loaners-item-header-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 20px 60px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);

      .reputation {
        position: absolute;
        left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        @include fontProps("Manrope", 20px, 600, #ffffff);
      }

      @media screen and(max-width: 900px) {
        padding: 10px 20px;
      }
      &.blocked {
        background: #efefef;
      }
      &.active {
        background: linear-gradient(90deg, #ececfe, #fdecf6);
      }
      .loaners-item-actions-wrapper {
        display: flex;
        align-items: center;
        @media screen and(max-width: 900px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .user-name {
          max-width: 250px;
          @include fontProps("Manrope", 24px, 600, #404040, 33px);
        }
        .loaner-actions {
          display: flex;
          margin-left: 40px;
          @media screen and(max-width: 900px) {
            padding: 10px 0px;
            margin-left: 0px;
          }
          .bth-content-wrapper {
            display: flex;
            flex-direction: column;
            .text-spinner {
              margin-top: 5px;
              text-align: center;
              animation: blinker 1s linear infinite;
              @include fontProps("Manrope", 12px, bold, #424770, 16px);
            }
            .text-error {
              margin-top: 5px;
              text-align: center;
              @include fontProps("Manrope", 12px, bold, red, 16px);
            }
            @keyframes blinker {
              50% {
                opacity: 0;
              }
            }
            .bth-border-wrapper {
              background: linear-gradient(
                  90deg,
                  #2b3dff -13.54%,
                  #ff409a 108.96%
                ),
                #ff7472;
              padding: 2px;
              border-radius: 8px;
              &.disabled {
                pointer-events: none;
                opacity: 0.3;
                background: grey;
                .bth {
                  background: #d5d0d0;
                }
              }
              .bth {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px 20px;
                background: linear-gradient(90deg, #d1c9f8, #efcaea);
                border-radius: 8px;
                @include fontProps("Manrope", 14px, bold, #424770, 19px);
              }

              &.bth-border-block-loaner-wrapper {
                margin-left: 15px;
                display: flex;
                align-items: center;
                .icon {
                  margin-right: 6px;
                  width: 22px;
                  height: 22px;
                }
              }
              &.bth-border-unblock-loaner-wrapper {
                background: #000000;
                margin-left: 40px;
                .bth-unblock-loaner {
                  background: #d6d6d6;
                }
              }
            }
          }
        }
      }
      .close-img-wrapper {
        cursor: pointer;
      }
    }
    .spinner-wrapper,
    .error-wrapper,
    .success-wrapper {
      margin-top: 50px;
    }
    .main-content-wrapper {
      margin-top: 17px;
      display: flex;
      padding: 0 60px;
      border-bottom: 1px solid grey;
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }
      .left-block-wrapper,
      .right-block-wrapper {
        width: 50%;
        padding-bottom: 60px;
        @media screen and(max-width: 900px) {
          width: 100%;
          padding-bottom: 30px;
        }
        .user-info-block-wrapper {
          .block-title {
            @include fontProps("Manrope", 16px, bold, #404040, 22px);
          }
          .block-content-wrapper {
            padding-right: 20px;
            margin-top: 16px;
            @media screen and(max-width: 450px) {
              padding-right: 0px;
            }
            .info-item {
              padding: 10px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media screen and(max-width: 450px) {
                flex-direction: column;
                align-items: flex-start;
              }
              .title {
                padding-right: 10px;
                @include fontProps("Manrope", 14px, 600, #404040, 19px);
              }
              .value {
                @include fontProps("Manrope", 14px, 600, #404040, 19px);
                flex: 1;
                text-align: right;
                background: none;
                border: none;
                &.active {
                  background: #f9f9f9;
                  border-radius: 4px;
                  padding: 4px;
                  cursor: pointer;
                }
                &.disabled {
                  pointer-events: none;
                }
                &:focus {
                  outline: none;
                  border: 1px solid #000000;
                  box-sizing: border-box;
                }
              }
              &:not(:first-child) {
                border-top: 1px solid grey;
              }
            }
          }
        }
      }
      .left-block-wrapper {
        border-right: 1px solid grey;
        @media screen and(max-width: 900px) {
          border-right: none;
        }
        .user-photos-block-wrapper {
          .photos-list-wrapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            @media screen and(max-width: 680px) {
              flex-direction: column;
              padding-right: 0px;
            }
            .photo-item-wrapper {
              width: 30%;
              // background: linear-gradient(90deg, #ececfe, #fdecf6);
              .spinner-wrapper {
                margin-top: 0;
              }
              @media screen and(max-width:680px) {
                margin-top: 10px;
                width: 100%;
              }
              img {
                width: 100%;
                border-radius: 8px;
                cursor: pointer;
              }
            }
          }
        }
        .user-contact-info-block-wrapper {
          margin-top: 32px;
        }
        .user-personal-info-block-wrapper {
          margin-top: 40px;
        }
      }
      .right-block-wrapper {
        padding-left: 20px;
        @media screen and(max-width: 900px) {
          padding-left: 0px;
        }
        .user-adrress-info-block-wrapper {
          margin-top: 40px;
        }
      }
    }
    .loan-content-wrapper {
      padding: 30px 60px;
      border-bottom: 1px solid grey;
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 10px 20px;
      }
      .loan-item-info-wrapper {
        padding: 15px 0;
        display: flex;
        align-items: center;
        @media screen and(max-width: 450px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .title {
          width: 150px;
          @include fontProps("Manrope", 14px, bold, #000000, 19px);
        }
        .value {
          @include fontProps("Manrope", 14px, 600, #404040, 19px);
        }
      }
    }
    .loans-history-content-wrapper {
      padding: 0 60px;
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }
      .loans-history-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .loans-history-table-title {
          @include fontProps("Manrope", 20px, 600, #404040, 27px);
        }
        .bth-border-wrapper {
          background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
            #ff7472;
          padding: 2px;
          border-radius: 8px;
          .bth {
            cursor: pointer;
            padding: 10px 20px;
            background: linear-gradient(90deg, #d1c9f8, #efcaea);
            border-radius: 8px;
            @include fontProps("Manrope", 14px, bold, #424770, 19px);
          }
          &.bth-border-block-loaner-wrapper {
            margin-left: 15px;
            display: flex;
            align-items: center;
            .icon {
              margin-right: 10px;
              width: 14px;
            }
          }
        }
      }

      .table-container {
        margin-top: 30px;
        .loans-history-table-head {
          background: linear-gradient(90deg, #ececfe, #fdecf6);
          .table-head-cell {
            border-bottom: none;
            padding: 11px 20px;
            @include fontProps("Manrope", 14px, bold, #404040, 19px);
          }
        }
        .loans-history-table-body {
          .loans-history-notification {
            margin-top: 15px;
            @include fontProps("Manrope", 13px, 500, #404040, 18px);
          }
          .loans-history-body-row {
            cursor: pointer;
            .table-body-cell {
              padding: 20px 16px;
              @include fontProps("Manrope", 13px, 500, #404040, 18px);
            }
            &:hover {
              background: #fafafa;
            }
          }
        }
      }
    }
    .bths-actions-wrapper {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      .bth-action-wrapper {
        .bth {
          background: none;
          border: none;
          padding: 10px 50px;
          border-radius: 8px;
          @include fontProps("Manrope", 14px, 600, #ffffff, 18px);
          &.bth-photo-not-match {
            color: #000000;
            background: #e8e5e9;
          }
          &.bth-deny {
            background: #eb5757;
          }
          &.bth-accept {
            background: #219653;
          }
          &.bth-block {
            background: #000000;
          }
        }
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
  @include scrollBar();
}
