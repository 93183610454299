@import "../../../styles/mixins.scss";

.alert-modal-wrapper {
  .MuiPaper-root {
    width: 100%;
    max-width: 400px;
  }
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .alert-content {
    padding: 50px 35px !important;
    position: relative;

    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    .info-text {
      text-align: center;
      margin-top: 10px;
      @include fontProps("Manrope", 18px, bold, #404040, 25px);
    }

    .modal-text-content {
      margin-top: 16px;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;

    .dismiss-bths-wrapper {
      margin-right: 4px;
    }

    .dismiss-bths-wrapper,
    .payment-options-wrapper {
      width: 100%;
    }

    .dismiss-request-bth {
      height: 100%;
    }

    .dismiss-bths-wrapper .bth-wrapper .bth {
      padding: 0 !important;
    }

    .bth-wrapper.make-payment-bth-wrapper {
      width: 100%;
    }
  }
}
