@import '../../../styles/mixins.scss';

.password-validator-message-wrapper {
  padding: 18px;
  background: #ffffff;
  position: absolute;
  left: 108%;
  top: -12px;
  width: 100%;
  max-width: 259px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  @media only screen and (max-width: 890px) {
    position: relative;
    left: 0;
    top: 0;
    max-width: 100%;
    margin-top: 20px;
  }
  .title {
    @include fontProps('Manrope', 16px, normal, #000000, 24px);
  }
  .list-validators-wrapper {
    .item-validator-wrapper {
      margin-top: 15px;
      display: flex;
      align-items: center;
      @include fontProps('Manrope', 16px, normal, #000000, 32px);
      .status-icon {
        margin-right: 15px;
      }
    }
  }
}
.password-validator-message-wrapper::before {
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 15px 20px 15px 0;
  border-color: transparent #ffffff transparent transparent;
  display: inline-block;
  position: absolute;
  top: 40px;
  left: -20px;
  @media only screen and (max-width: 890px) {
    top: 0;
    left: 0;
    display: none;
  }
}
