@import '../../../../../styles/mixins.scss';

.sms-modal-vertification-wrapper {
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .MuiDialog-container {
    #alert-dialog-title {
      @include fontProps('Manrope', 15px, 600, #424770, 22px);
      text-align: center;
    }
    .MuiPaper-root {
      max-width: 546px;
      max-height: none;
      width: 100%;
      padding: 40px;
      align-items: center;
      @media only screen and (max-width: 768px) {
        padding: 40px 20px 20px 20px;
        margin: 20px;
      }
      .content-dialog {
        padding: 0;
        width: inherit;
        .close-icon-wrapper {
          position: absolute;
          top: 10px;
          right: 14px;
          cursor: pointer;
        }
        .timer-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          @include fontProps('Manrope', 16px, 500, #000000, 25px);
        }
        .code-expired-info-wrapper {
          margin-top: 30px;
          .message {
            text-align: center;
            @include fontProps('Manrope', 16px, 500, #eb5757, 25px);
          }
          .send-code-bth-wrapper {
            margin-top: 10px;
            text-align: center;
            opacity: 0.5;
            display: flex;
            justify-content: center;
            .img-wrapper {
              margin-right: 10px;
            }
            .send-code-bth {
              @include fontProps('Manrope', 16px, 500, #000000, 20px);
              border: none;
              background: none;
            }
          }
        }
      }
      .verification-code-styles {
        --ReactInputVerificationCode-itemWidth: 4.5rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        display: flex;
        justify-content: center;
        .ReactInputVerificationCode__item {
          position: relative;
          color: #262626;
          font-weight: 500;
          box-shadow: none;
          @media only screen and (max-width: 768px) {
            width: 50px;
          }
          &:after {
            border-bottom: 2px solid #424770;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 2px;
          }
          &.is-active {
            box-shadow: none;
          }
          &.is-active:after {
            border-bottom: 2px solid red;
          }
        }
      }
      .info-pending-block,
      .info-error-block {
        @include fontProps('Manrope', 16px, 500, #000000, 20px);
        text-align: center;
        margin-top: 15px;
      }
      .info-error-block {
        color: red;
      }
      .info-pending-block {
        animation: blinker 1s linear infinite;
      }
      .send-code-bth {
        background: none;
        border: none;
        margin-top: 10px;
        text-align: center;
        width: 100%;
        @include fontProps('Manrope', 16px, bold, #000000, 20px);
      }
      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
      .send-code-info-wrapper {
        display: flex;
        margin-top: 50px;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        .send-code-bth-wrapper {
          margin-left: 10px;
          .send-code-bth {
            @include fontProps('Manrope', 16px, 500, #000000, 20px);
            border: none;
            background: none;
            &:hover {
              transform: scale(1.04);
            }
          }
        }
      }
    }
  }
}
