@import "../../../../../styles/mixins.scss";

.loaners-table-wrapper {
  @include scrollBar();
  background: #ffffff;
  flex: 1;
  margin-top: 25px;
  .spinner-wrapper {
    margin-top: 50px;
  }
  .error-wrapper {
    margin-top: 50px;
  }
  .table-container {
    .MuiTable-root {
      min-width: 700px;
    }
    .loaners-table-head {
      .table-head-cell {
        border-bottom: none;
        padding: 25px 16px;
        @include fontProps("Manrope", 14px, bold, #404040, 19px);
        .sort-icon {
          margin-left: 10px;
        }
        &:first-child {
          padding-left: 50px;
          padding-right: 0;
          @media screen and(max-width: 550px) {
            padding-left: 20px;
          }
        }
        &:nth-child(2) {
          padding: 25px 0;
        }
      }
    }
    .loaners-table-body {
      .loaners-empty {
        position: absolute;
        padding-left: 10px;
        @include fontProps("Manrope", 20px, 500, #404040, 25px);
      }
      .loaners-table-body-row {
        cursor: pointer;

        .table-body-cell {
          padding: 20px 16px;
          @include fontProps("Manrope", 13px, 500, #404040, 18px);
          input {
            width: 20px;
            height: 20px;
          }
          &:first-child {
            padding-left: 50px;
            padding-right: 30px;
            width: 10px;
            @media screen and(max-width: 550px) {
              padding-left: 20px;
            }
          }
          &:nth-child(2) {
            padding: 25px 0;
          }

          img {
            width: 15px;
          }

          .indicator {
            width: 10px;
            height: 10px;
            border-radius: 50%;

            display: block;
          }
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
}
