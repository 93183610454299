@import "../../../../../../styles//mixins.scss";
.photo-veftification-info-step {
  form.photo-veftification-info {
    padding: 40px;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      padding: 15px;
    }
    .required-fields {
      width: 100%;
      padding: 0 10px;
      @include fontProps("Manrope", 16px, 600, #000000, 22px);
      @media only screen and (max-width: 768px) {
        padding: 0;
      }
      .start {
        color: red;
      }
    }
    .form-title-wrapper {
      text-align: center;
      @media only screen and (max-width: 768px) {
        margin-top: 6px;
      }
      .title {
        @include fontProps("Manrope", 28px, 800, #424770, 36px);
        @media only screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 26px;
        }
      }
      .description {
        @include fontProps("Manrope", 22px, 600, #424770, 30px);
        margin-top: 16px;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
    .load-photos-block-wrapper {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-top: 0;
      }
      .load-photo-block-wrapper {
        width: 25%;
        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-top: 20px;
        }
        .title {
          @include fontProps("Manrope", 18px, bold, #424770, 25px);
          text-align: center;
          .start {
            color: red;
          }
        }
        .bths-create-photo-wrapper {
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          .bth-action {
            width: 100%;
            max-width: 112px;
            border: 1px solid #424770;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: none;
            @include fontProps("Manrope", 14px, bold, #424770, 19px);
            .MuiButton-root {
              padding: 6px;
              width: 100%;
            }
            button {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: none;
              border: none;
              @include fontProps("Manrope", 14px, bold, #424770, 19px);
              .camera-img {
                margin-right: 8px;
              }
            }
          }
        }
        .loaded-photo-info {
          display: flex;
          justify-content: space-around;
          margin-top: 10px;
          .dni-photo-name,
          .bth-action {
            width: 40%;
            text-align: center;
          }
          .dni-photo-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .bth-action {
            button {
              background: none;
              border: none;
              color: #000000;
              opacity: 0.5;
            }
          }
        }
        .webcam-block-wrapper {
          button {
            border: 1px solid #424770;
            box-sizing: border-box;
            border-radius: 8px;
            width: 100%;
            max-width: 112px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            padding: 8px;
            @include fontProps("Manrope", 14px, bold, #424770, 19px);
            .camera-img {
              margin-right: 8px;
            }
          }
        }
        .img-sample-wrapper {
          position: relative;
          margin-top: 30px;

          img {
            width: 100%;
            border-radius: 8px;
          }

          @media only screen and (max-width: 768px) {
            max-width: 300px;
            margin: 30px auto;
          }

          .overlay {
            border-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000000;
            opacity: 0.2;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            @include fontProps("Manrope", 32px, bold, #ffffff, 44px);
          }
        }
      }
    }
    .step-processing-policy {
      margin: 40px auto 0 auto;
      max-width: 543px;
      text-align: center;
      @include fontProps("Manrope", 16px, normal, #000000, 28px);
      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
    .bths-wrapper {
      width: 100%;
      margin-top: 60px;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin-top: 0;
      }
      .bth-action {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 246px;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
          margin-top: 30px;
        }
        button {
          width: 100%;
          max-width: 246px;
          padding: 13px;
          border: none;
          background: none;
          background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
            #ff7472;
          box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
          border-radius: 8px;
          @include fontProps("Manrope", 18px, 600, #ffffff, 25px);
          @include activationFormBthEffect;
          @media only screen and (max-width: 768px) {
            max-width: 100%;
          }
        }
      }
      .bth-back-wrapper {
        margin-right: 10px;
        button {
          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
              ),
              linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
          }
        }
      }
    }
  }
}
