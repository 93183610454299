@import '../../../../../../../styles/mixins.scss';
.get-money-options-wrapper {
  .get-money-bths-wrapper {
    margin-top: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 40px;
    }
    .bth-wrapper {
      width: 45%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
      .bth {
        padding: 10px;
        width: 100%;
        border: none;
        @include fontProps('Manrope', 14px, bold, #404040, 19px);
        &.get-money-bth {
          @include gradiendBgWithHoverEffect();
        }
      }
      &.refuse-loan-bth-wrapper {
        @include bthGradiendBorderWrapper();
        .refuse-loan-bth {
          @include bthWithGradiendBorder();
        }
      }
    }
  }
}
