.container {
  width: 100%;
  height: 500px;
}

.echarts-for-react {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;

  div:first-child {
    width: 100% !important;
    height: 100% !important;
  }
}
