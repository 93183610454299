@import "../../../../../../../styles//mixins.scss";

.historical-user-wrapper {
  .spinner-wrapper,
  .error-wrapper {
    margin-top: 50px;
  }

  .MuiPaper-root {
    width: 100%;
    max-width: 1000px;
    height: 80vh;
  }
  .MuiDialog-paper {
    margin: 10px;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .historical-user-content {
    padding: 0 0 30px 0 !important;
    @media screen and(max-width: 720px) {
      padding: 0 0 20px 0 !important;
    }

    .rigth-section,
    .left-section {
      padding: 0 10px;
    }

    .historical-user-table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .historical-user-table-title {
        @include fontProps("Manrope", 20px, 600, #404040, 27px);
      }
      .bth-border-wrapper {
        background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
          #ff7472;
        padding: 2px;
        border-radius: 8px;
        .bth {
          cursor: pointer;
          padding: 10px 20px;
          background: linear-gradient(90deg, #d1c9f8, #efcaea);
          border-radius: 8px;
          @include fontProps("Manrope", 14px, bold, #424770, 19px);
        }
        &.bth-border-block-loaner-wrapper {
          margin-left: 15px;
          display: flex;
          align-items: center;
          .icon {
            margin-right: 10px;
            width: 14px;
          }
        }
      }
    }

    .table-container {
      th,
      td {
        &:first-child {
          width: 15%;
          text-align: center;
        }

        &:last-child {
          width: 20%;
          text-align: center;
        }

        &.comment-content {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .historical-user-table-head {
        background: linear-gradient(90deg, #ececfe, #fdecf6);
        .table-head-cell {
          border-bottom: none;
          padding: 11px 20px;
          @include fontProps("Manrope", 14px, bold, #404040, 19px);
        }
      }
      .historical-user-table-body {
        .historical-user-notification {
          margin-top: 15px;
          @include fontProps("Manrope", 13px, 500, #404040, 18px);
          text-align: center;
        }
        .historical-user-body-row {
          cursor: pointer;
          .table-body-cell {
            padding: 20px 16px;
            @include fontProps("Manrope", 13px, 500, #404040, 18px);
          }
          &:hover {
            background: #fafafa;
          }
        }
      }
    }

    .header-wrapper {
      padding: 22px 42px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      display: flex;
      justify-content: flex-start;
      position: relative;

      .close-img-wrapper {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .header-title {
        @include fontProps("Manrope", 16px, 600, #000000, 22px);
        margin-right: 50px;
      }

      @media screen and(max-width: 720px) {
        padding: 10px 20px;
        flex-wrap: wrap;
      }

      .user-item-wrapper {
        display: flex;
        align-items: center;

        &:first-child {
          margin-bottom: 20px;
        }

        @media screen and(max-width: 720px) {
          max-width: 80%;
          align-items: flex-start;
        }

        .user-item-value {
          @include fontProps("Manrope", 16px, 600, #404040, 22px);

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      width: 70%;
      justify-content: center;
      margin: auto;

      button:disabled {
        opacity: 0.3;
      }

      .dismiss-bths-wrapper,
      .payment-bths-wrapper {
        width: 100%;

        padding: 0 10px;
      }

      .bth-wrapper {
        width: 100%;
      }
    }

    .main-content-wrapper {
      padding: 0 30px;
      // margin-top: 50px;

      @media screen and(max-width: 720px) {
        padding: 0 20px;
        margin-top: 30px;
      }

      .comments-list {
        max-height: 280px;
        min-height: 280px;
        overflow-y: auto;
        margin-top: 30px;

        .comment-container {
          /*           overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 80%; */
        }
      }

      .comment-box {
        display: flex;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #404040;
        justify-content: space-between;

        .textarea-wrapper {
          width: 72%;

          .textarea-title {
            text-align: center;
            @include fontProps("Manrope", 24px, 600, #404040, 33px);
          }

          textarea {
            width: 100%;
            height: 100%;
            border: 1px solid #424770;
            border-radius: 8px;
            padding: 15px;
            resize: none;
            &:focus {
              outline: none;
              border: 1px solid #000000;
            }
          }
        }
      }

      .bth-content-wrapper {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .text-spinner {
          margin-top: 5px;
          text-align: center;
          animation: blinker 1s linear infinite;
          @include fontProps("Manrope", 12px, bold, #424770, 16px);
        }
        .text-error {
          margin-top: 5px;
          text-align: center;
          @include fontProps("Manrope", 12px, bold, red, 16px);
        }
        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
        .bth-border-wrapper {
          background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
            #ff7472;
          padding: 2px;
          border-radius: 8px;
          &.disabled {
            pointer-events: none;
            opacity: 0.3;
            background: grey;
            .bth {
              background: #d5d0d0;
            }
          }
          .bth {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px 20px;
            background: linear-gradient(90deg, #d1c9f8, #efcaea);
            border-radius: 8px;
            @include fontProps("Manrope", 14px, bold, #424770, 19px);
          }
        }
      }
    }
  }
  @include scrollBar();
}
