@import "../../../styles//mixins.scss";

.tabs-wrapper {
  display: flex;
  flex: 1;

  .drawer-container {
    width: 250px;

    @media only screen and (max-width: 768px) {
      height: 100vh;
      overflow: hidden;
      position: fixed;
      top: 0;
      transition: all 0.1s ease;
      visibility: hidden;
      width: 0;
      width: 0;
      z-index: 2;

      &.open {
        width: 100%;
        visibility: visible;
        background-color: #f9f2f2ba;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
          0px 2px 6px rgba(0, 0, 0, 0.04);
      }
    }
  }

  .left-block-wrapper-nav {
    display: flex;
    flex-direction: column;
    width: 250px;
    background: #f9f2f2;
    padding-left: 21px;
    padding-top: 21px;
    height: 100%;

    @media only screen and (max-width: 768px) {
      width: 270px;
      padding-left: 10px;
      box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.25);
      z-index: 2;
      position: fixed;
      top: 0;
      height: 100vh;
      overflow: hidden;
    }

    .navigation-tabs-wrapper {
      .navigation-tab-item {
        position: relative;
        border-radius: 8px;
        max-width: 200px;
        display: flex;
        align-items: center;
        padding: 8px 0;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
          white-space: nowrap;
          max-width: 250px;
        }
        @include fontProps("Manrope", 16px, 600, #424770, 22px);
        &.active {
          background: linear-gradient(90deg, #ececfe, #fdecf6);
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .navigation-tab-item-icon {
          margin-left: 10px;
          margin-right: 15px;
        }
        &:not(:first-child) {
          margin-top: 12px;
        }
        &:first-child {
          margin-top: 50px;
        }
      }
    }
    .activeBorder {
      display: flex;
      height: auto;
      position: absolute;
      left: 0;
    }
    .disabledBorder {
      display: none;
    }
    .bth-contact-us-wrapper {
      margin-top: 50px;
      width: 200px;
      white-space: nowrap;
      @media only screen and (max-width: 768px) {
        width: 250px;
      }
      @include bthGradiendBorderWrapper();
      .bth-contact-us {
        @include bthWithGradiendBorder();
        width: 100%;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f2f2;
        @include fontProps("Manrope", 13px, 600, #424770, 19px);
        img {
          margin-right: 10px;
        }
        &:hover {
          img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(293deg)
              brightness(115%) contrast(101%);
          }
        }
      }
    }
    .user-header-info-wrapper {
      flex: 1;
      display: none;
      align-items: center;
      margin-bottom: 30px;
      @media only screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  .tab-panels-wrapper {
    background-color: #fdfbfc;
    flex: 1;
    padding-top: 21px;
    @media only screen and (max-width: 768px) {
      padding-top: 0;
      width: 100%;
    }
  }
  .tab-panel-wrapper {
    padding: 0 21px 21px 21px;
    height: 100%;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
}
