@import "../../../../../styles/mixins.scss";
.loan-tab-wrapper {
  @include userProfileTabPannelWrapper(
    100%,
    650px,
    100%,
    35px,
    8px,
    rgba(255, 255, 255, 0.5)
  );
  position: relative;

  @media only screen and (max-width: 768px) {
    .top-banner-content__calculator__content {
      padding: 16px 20px 16px 20px;
    }
  }
}
