@import '../../../../../styles/mixins.scss';

.modal-change-loan-terms-wrapper {
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .MuiDialog-container {
    .MuiPaper-root {
      max-width: 610px;
      width: 100%;
      padding: 40px;
      .content-dialog {
        padding: 5px;
        margin: 10px;
        .close-img {
          position: absolute;
          right: 30px;
          top: 30px;
          cursor: pointer;
        }
        .form-title {
          @include fontProps('Manrope', 20px, 600, #1a203d, 27px);
          text-align: center;
        }
        .remaining-balance-slider-wrapper {
          width: 85%;
        }
        .calculator__slider__wrapper {
          width: 90%;
          margin: 20px auto 0 auto;
        }
        .bth-submit-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 40px;
          .bth-description {
            @include fontProps('Manrope', 14px, 500, #404040, 24px);
            text-align: center;
          }
          .bth-request-changes {
            margin-top: 20px;
            padding: 10px;
            max-width: 256px;
            width: 100%;
            border: none;
            background: linear-gradient(
              90deg,
              #2b3dff -13.54%,
              #ff409a 108.96%
            );
            border-radius: 8px;
            @include fontProps('Manrope', 14px, bold, #ffffff, 19px);
          }
        }
      }
    }
  }
  @include scrollBar;
}
