@import "../../../../../../styles//mixins.scss";

.modal-contract-item-wrapper {
  .spinner-wrapper,
  .error-wrapper {
    margin-top: 50px;
  }

  .MuiPaper-root {
    width: 100%;
    max-width: 950px;
  }
  .MuiDialog-paper {
    margin: 10px;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .contract-item-content {
    padding: 0 0 40px 0 !important;
    @media screen and(max-width: 720px) {
      padding: 0 0 20px 0 !important;
    }
    .contract-item-header-wrapper {
      padding: 22px 42px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      display: flex;
      justify-content: space-between;
      @media screen and(max-width: 720px) {
        padding: 10px 20px;
        flex-wrap: wrap;
      }

      .contract-id {
        @include fontProps("Manrope", 20px, 600, #404040, 27px);
        @media screen and(max-width: 720px) {
          width: 100%;
          order: 1;
          margin-top: 15px;
        }
      }
      .user-name-wrapper {
        display: flex;
        align-items: center;
        @media screen and(max-width: 720px) {
          max-width: 80%;
          align-items: flex-start;
        }
        .user-name {
          @include fontProps("Manrope", 16px, 600, #404040, 22px);
        }
        .user-name-icon-wrapper {
          margin-left: 15px;
        }
      }
    }
    .main-content-wrapper {
      padding: 0 42px;
      margin-top: 50px;
      @media screen and(max-width: 720px) {
        padding: 0 20px;
        margin-top: 30px;
      }
      .contract-history-item-table-wrapper {
        .contract-history-table-head {
          background: linear-gradient(90deg, #ececfe, #fdecf6);
          .table-head-cell {
            padding: 12px 34px;
            border: none;
            @include fontProps("Manrope", 14px, bold, #424770, 19px);
            &:first-child {
              width: 250px;
            }
            &:last-child {
              text-align: right;
            }
          }
        }
        .contract-history-table-body {
          .empty-data-notifaction {
            @include fontProps("Manrope", 12px, bold, #424770, 19px);
            margin-top: 10px;
            margin-left: 10px;
          }
          .contract-history-table-body-row {
            .table-body-cell {
              padding: 24px 34px;
              @include fontProps("Manrope", 14px, bold, #424770, 19px);
              .indicator {
                width: 10px;
                height: 10px;
                background-color: green;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
              }
              &:first-child {
                width: 250px;
              }
              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }

      .extra-info-section {
        padding: 0 20px;
        margin-top: 20px;

        .bank-account-info {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          margin-top: 20px;
          @include fontProps("Manrope", 14px, normal, #424770, 19px);

          span {
            @include fontProps("Manrope", 15px, bold, #424770, 19px);
          }

          &.references-info {
            flex-direction: column;
            div:not(:last-child) {
              margin-bottom: 20px;
            }

            .title-section {
              @include fontProps("Manrope", 15px, normal, #424770, 21px);
            }

            span:last-child {
              margin-left: 10px;
            }
          }
        }

        .observations-section {
          font-family: "Manrope";
          margin-top: 20px;

          .section-title {
            margin-top: 20px;
            @include fontProps("Manrope", 15px, bold, #424770, 19px);
          }

          .section-content {
            margin-top: 20px;
          }
        }
      }

      .bth-actions-wrapper {
        display: flex;
        align-items: center;
        margin-top: 48px;
        @media screen and(max-width: 720px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .download-pdf-bths-wrapper {
          margin-top: 0;
          padding: 5px 0;
          margin-left: 0px;
          .error-wrapper {
            margin-top: 0;
          }
        }
        .contract-actions {
          display: flex;
          .bth-border-wrapper {
            background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
              #ff7472;
            padding: 2px;
            border-radius: 8px;
            .bth {
              cursor: pointer;
              padding: 10px 20px;
              background: linear-gradient(90deg, #ececfe, #fdecf6);
              border-radius: 8px;
              @include fontProps("Manrope", 14px, bold, #424770, 19px);
            }
            &.bth-border-hopeless-delay-wrapper {
              margin-left: 15px;
            }
          }
        }
        .pdf-bths-wrapper {
          margin-left: 50px;
          display: flex;
          @media screen and(max-width: 720px) {
            margin-left: 0px;
            margin-top: 20px;
          }
          .download-pdf-bth-wrapper {
            display: flex;
            align-items: center;
            @include fontProps("Manrope", 14px, bold, #1a203d, 19px);
            .text {
              margin-left: 8px;
            }
            img.pdf-icon {
              width: 40px;
            }
            &.download-loan-request {
              margin-left: 32px;
            }
          }
        }
        .current-sum {
          flex: 1;
          text-align: right;
          @include fontProps("Manrope", 28px, bold, #404040, 28px);
        }
      }
    }
  }
  @include scrollBar();
}
