@import '../../../../../../../styles/mixins.scss';

.choose-another-loan-bth-wrapper {
  margin-top: 24px;
  border-radius: 8px;
  .bth {
    @include gradientBth(272px);
    @include gradiendBgWithHoverEffect();
  }
}
