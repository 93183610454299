@import "../../../../styles/mixins.scss";

.loan-history-tab-wrapper {
  @include userProfileTabPannelWrapper(
    100%,
    850px,
    100%,
    35px,
    8px,
    rgba(255, 255, 255, 0.5)
  );
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  .tab-title {
    @include fontProps("Manrope", 24px, 600, #1a203d, 33px);
    @media only screen and (max-width: 768px) {
      @include fontProps("Manrope", 20px, 600, #1a203d, 27px);
      text-align: center;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      padding: 24px 0;
    }
  }

  .documents-block-wrapper {
    margin-top: 40px;
    @media only screen and (max-width: 768px) {
      margin-top: 10px;
    }
    .title {
      padding: 12px;
      @include fontProps("Manrope", 24px, 600, #404040, 33px);
      @media only screen and (max-width: 768px) {
        border-top: 0.5px solid #e0e0e0;
        padding: 16px;
      }
    }
    .list-documents-wrapper {
      .document-item-wrapper {
        width: 100%;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
          padding: 16px;
        }
        .desription {
          @include fontProps("Manrope", 14px, 600, #1a203d, 19px);
        }
        .bth-download {
          display: flex;
          align-items: center;
          border: none;
          padding: 0;
          background: none;
          @include fontProps("Manrope", 14px, 600, #1a203d, 19px);
          img {
            margin-right: 5px;
          }
        }
        &:not(:first-child) {
          border-top: 0.5px solid #ececec;
        }
      }
    }
  }
}

.loans-empty-wrapper {
  margin-top: 40px;

  .description {
    text-align: center;
    @include fontProps("Manrope", 14px, normal, #424770, 19px);
  }
  .new-loan-bth-wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    .bth {
      @include gradientBth(219px);
    }
  }
}
