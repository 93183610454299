@import "../../../../../../../styles/mixins.scss";
.loan-content-wrapper {
  margin-top: 20px;

  .initial-balance {
    margin-top: 30px;

    .balance-title {
      margin: 20px 0 34px;
      @include fontProps("Manrope", 18px, 600, #404040, 22px);
    }
  }

  .loan-content-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    .questionIcon__wrapper {
      margin-left: 9px;
      position: relative;
      @include questionIconWrapper();
      border: none;
      .questionInfo {
        position: absolute;
        visibility: hidden;
        width: 300px;
        background: #ffffff;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
          0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        left: -30px;
        text-align: center;
        border-radius: 6px;
        padding: 14px;
        position: absolute;
        bottom: 40px;
        margin-left: -60px;
        text-align: left;
        @media only screen and (max-width: 981px) {
          left: -70px;
        }
        @media only screen and (max-width: 768px) {
          left: -100px;
        }
        span {
          display: flex;
          justify-items: left;
        }

        .concept-item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:not(:first-child) {
            margin-top: 5px;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #cdcdcd;
            margin-bottom: 5px;
          }

          span {
            &:first-child {
              max-width: 75%;
            }
          }
        }
      }

      .questionInfo::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 13px;
        border-style: solid;
        border-color: #ffffff transparent transparent transparent;
        text-align: left;
      }
    }

    .questionIcon__wrapper:hover .questionInfo {
      visibility: visible;
    }
    .description {
      @include fontProps("Manrope", 16px, 600, #404040, 22px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 15px, 600, #404040, 20px);
      }
    }
    .value {
      @include fontProps("Manrope", 14px, normal, #404040, 19px);
    }
    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }
    &.amount-return {
      .value {
        display: flex;
        align-items: center;
        img {
          margin-left: 9px;
        }
      }
    }
    &.opacity {
      opacity: 0.3;
    }
    &.red-text {
      .description,
      .value {
        color: #eb5757;
      }
    }
  }
}
