@import '../../../../../../../styles/mixins.scss';

.loan-status-wrapper {
  .status-indicator-wrapper.bad-photo-loan {
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    margin-top: 10px;
    background: transparent;
    span:first-child {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #eb5757;
    }
    span:last-child {
      @include fontProps('Manrope', 14px, 500, #eb5757, 22px);
      margin-left: 12px;
      width: 80%;
      @media only screen and (max-width: 768px) {
        width: 98%;
      }
    }
  }
  .bth-ulpoad-photos-wrapper {
    margin-top: 16px;
    width: 100%;
    max-width: 200px;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
    @include bthGradiendBorderWrapper();
    .bth-ulpoad-photos {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include fontProps('Manrope', 14px, 600, #424770, 19px);
      @include bthWithGradiendBorder();
    }
  }
  .bad-photo-notification-wrapper {
    margin-top: 16px;
    max-width: 420px;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
    .notification-text {
      @include fontProps('Manrope', 14px, 500, #eb5757, 22px);
    }
  }
}
