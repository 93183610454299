@import '../../../../../styles/mixins.scss';

.inbox-table-wrapper {
  background: #ffffff;
  flex: 1;
  margin-top: 25px;
  .spinner-wrapper {
    margin-top: 50px;
  }
  .error-wrapper {
    margin-top: 50px;
  }
  .MuiTable-root {
    min-width: 350px;
  }

  .table-container {
    .inbox-table-head {
      .table-head-cell {
        border-bottom: none;
        padding: 25px 16px;
        @include fontProps('Manrope', 14px, bold, #404040, 19px);
        .sort-bth-wrapper {
          margin-left: 10px;
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: linear-gradient(90deg, #d1c9f3, #f1c9e2);
          }
          .sort-icon {
            &.up {
              transform: rotate(180deg);
            }
          }
        }

        &:first-child {
          padding-left: 50px;
          @media screen and(max-width: 550px) {
            padding-left: 20px;
          }
        }
      }
    }
    .inbox-empty-notification {
      margin-left: 50px;
      @include fontProps('Manrope', 20px, 600, #404040, 25px);
      position: absolute;
      @media screen and(max-width: 550px) {
        margin-left: 20px;
      }
    }
    .inbox-table-body {
      .inbox-table-body-row {
        cursor: pointer;
        .table-body-cell {
          padding: 20px 16px;
          @include fontProps('Manrope', 13px, 500, #404040, 18px);
          &:first-child {
            padding-left: 50px;
            @media screen and(max-width: 550px) {
              padding-left: 20px;
            }
          }
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
  @include scrollBar();
}
