@import "../../../../../styles/mixins.scss";

.balance-content-wrapper {
  background: #ffffff;
  flex: 1;
  margin-top: 25px;
  padding-left: 50px;
  padding-top: 20px;

  @media screen and(max-width: 550px) {
    padding-left: 20px;
  }

  .bank-info-wrapper {
    margin: 60px 0;
  }

  .bank-title {
    @include fontProps("Manrope", 18px, bold, #404040, 23px);
    margin-bottom: 15px;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }

  .counters {
    display: flex;
    align-items: center;

    .counter {
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
      margin-right: 40px;

      span {
        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .payment-bths-wrapper {
      margin-top: 0;
    }

    .payment-bths-wrapper .bth-wrapper {
      width: 100%;

      button:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }

  .table-head {
    .table-head-cell {
      border-bottom: none;
      padding: 25px 16px;
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:nth-child(2) {
        padding: 25px 0;
      }
    }
  }

  .input-filter-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 60px;

    label {
      @include fontProps("Manrope", 14px, 500, #404040, 20px);
      margin-bottom: 0;
      margin-right: 20px;
    }

    .input-item {
      width: 100%;
      max-width: 232px;
      padding: 10px 15px;
      background: rgba(245, 245, 245, 0.3);
      border: 1px solid rgba(200, 200, 200, 0.5);
      border-radius: 8px;
      font-family: "Manrope";
      font-weight: 600;
      font-size: 14px;
      color: #1a203d;
      line-height: 20px;
      .MuiInputBase-root::before {
        border: none;
      }
      .MuiInputBase-input {
        padding: 0;
      }

      .MuiIconButton-root {
        padding: 0;
      }

      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  .table-body {
    .empty-data {
      text-align: center;
      margin-top: 10px;
      padding-left: 10px;
      @include fontProps("Manrope", 16px, 500, #404040, 20px);
    }

    .spinner-wrapper .spinner-text {
      font-size: 14px;
    }

    .table-body-row {
      cursor: pointer;
      .table-body-cell {
        padding: 20px 16px;
        @include fontProps("Manrope", 13px, 500, #404040, 18px);
        text-align: center;

        .transaction-status-icon {
          display: flex;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
          }

          &.cancel-icon {
            img {
              border-radius: 50%;
              margin-right: 15px;
            }
          }
        }

        &:first-child {
          text-align: left;
        }

        &:nth-child(2) {
          padding: 25px 0;
        }
      }

      &:hover {
        background: #fafafa;
      }
    }
  }
}
