@import "../../../../../../styles/mixins.scss";

.tab-panel-title {
  display: flex;
  flex-direction: column;

  .contract-title {
    @include fontProps("Manrope", 24px, normal, #1a203d, 33px);
    @media only screen and (max-width: 768px) {
      @include fontProps("Manrope", 20px, bold, #1a203d, 27px);
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      padding: 24px 0;
    }
  }
  .contract-number-container {
    display: flex;
    justify-content: space-between;
  }
  .contract-identificator {
    @include fontProps("Manrope", 16px, normal, #1a203d, 33px);
  }
  .tick-wrapper {
    background: #4cc982;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 20px;
  }
}
