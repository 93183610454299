@import "../../../../../../styles//mixins.scss";
.address-info-step {
  .form-address-info {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      padding: 15px;
    }
    .required-fields {
      width: 100%;
      padding: 0 10px;
      @include fontProps("Manrope", 16px, 600, #000000, 22px);
      @media only screen and (max-width: 768px) {
        padding: 0;
      }
      .start {
        color: red;
      }
    }
    .form-item {
      width: 45%;
      margin-top: 32px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      .flex-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
      }
      label.label-item {
        @include fontProps("Manrope", 16px, 600, #000000, 22px);
        margin: 0;
        padding: 0 10px;
        @media only screen and (max-width: 768px) {
          padding: 0;
          width: 100%;
        }
        .start {
          color: red;
        }
      }
      .MuiSelect-root {
        &:focus {
          background-color: transparent;
        }
      }

      .MuiInputBase-root {
        &::before,
        &::after {
          border: none !important;
        }
      }
      .input-item {
        width: 100%;
        max-width: 232px;
        padding: 10px 15px;
        background: rgba(245, 245, 245, 0.3);
        border: 1px solid rgba(200, 200, 200, 0.5);
        border-radius: 8px;
        @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
        &:focus {
          outline: none;
          border: 2px solid #696565;
        }
        @media only screen and (max-width: 768px) {
          max-width: 100%;
          margin-top: 6px;
        }
      }
      .error-text {
        text-align: right;
      }
    }
    .bths-wrapper {
      width: 100%;
      margin-top: 60px;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin-top: 0;
      }
      .bth-action {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 246px;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
          margin-top: 30px;
        }
        button {
          width: 100%;
          max-width: 246px;
          padding: 13px;
          border: none;
          background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
            #ff7472;
          box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
          border-radius: 8px;
          @include fontProps("Manrope", 18px, 600, #ffffff, 25px);
          @include activationFormBthEffect;
          @media only screen and (max-width: 768px) {
            max-width: 100%;
          }
        }

        &.bth-back-wrapper {
          margin-right: 10px;
          button {
            &:hover {
              background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.2),
                  rgba(0, 0, 0, 0.2)
                ),
                linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
                #ff7472;
            }
          }
        }
      }
    }
  }
}
