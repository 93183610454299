@font-face {
  font-family: "Manrope";
  src: local("Manrope  Regular"), local("Manrope--Regular"),
    url("Manrope-Regular.woff2") format("woff2"),
    url("Manrope-Regular.woff") format("woff"),
    url("Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
