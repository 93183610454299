@import "../../../styles/mixins.scss";

.notifications-block-wrapper {
  z-index: 2;
  width: 100%;
  max-width: 350px;
  position: absolute;
  right: 21px;
  top: calc(100% + 21px);
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  padding: 20px 0;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04);
  opacity: 1;
  height: auto;
  max-height: 0;
  padding: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.7s ease;
  @media only screen and (max-width: 768px) {
    right: 0;
    top: 100%;
    max-width: 100%;
  }
  &.open {
    max-height: 506px;
    padding: 20px 0;
    visibility: visible;
    @media only screen and (max-width: 768px) {
      max-height: 300px;
      .notification-list-wrapper {
        max-height: 166px;
      }
    }
  }
  .block-title {
    padding: 0 20px;
    text-transform: uppercase;
    @include fontProps("Manrope", 12px, normal, #4c4c66, 16px);
    @media only screen and (max-width: 768px) {
      text-align: center;
      text-transform: capitalize;
      @include fontProps("Manrope", 20px, bold, #4c4c66, 26px);
    }
  }
  .spinner-wrapper,
  .error-wrapper {
    margin-top: 20px;
  }
  .notification-list-wrapper {
    margin-top: 25px;
    max-height: 372px;
    overflow-y: auto;
    .info-block-empty {
      padding: 0 20px;
      @include fontProps("Manrope", 16px, 600, #4c4c66, 24px);
    }
    .notification-item-wrapper {
      .notification-item-content {
        display: flex;
        padding: 10px 20px;
        .notification-item-indicator {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          box-shadow: 0px 0px 2px #747474;
        }
        .notification-item-text {
          margin-left: 15px;
          max-width: 90%;

          &-read {
            opacity: 0.5;
          }

          .notification-item-content-main-text {
            @include fontProps("Manrope", 14px, 600, #4c4c66, 22px);
          }
          .notification-item-content-hours {
            @include fontProps("Manrope", 11px, normal, #6f6c99, 15px);
            margin-top: 4px;
          }
        }
      }
    }
  }
  .mark-readed-bth {
    @include fontProps("Manrope", 12px, 500, #424770, 16px);
    background: none;
    border: none;
    width: 100%;
    margin-top: 20px;
  }
  @include scrollBar;
}
