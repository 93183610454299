@import "../../../../styles//mixins.scss";

.profile-header-user-info {
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  .username-wrapper {
    margin-left: 30px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
    .username {
      margin-left: 12px;
      @include fontProps("Manrope", 16px, 600, #424770, 22px);
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
      overflow: hidden;
    }
  }
  .logout-bth-wrapper {
    margin-left: 30px;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: 20px;
    }

    span {
      margin-left: 12px;
      @include fontProps("Manrope", 16px, 600, #424770, 22px);
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
      overflow: hidden;
    }

    cursor: pointer;
    img {
      &:hover {
        transform: scale(1.2);
        transition: transform 0.2s ease-in-out;
      }
    }
  }
}
