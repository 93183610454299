@import "../../../../../../styles//mixins.scss";

body {
  overflow: visible !important;
}

.modal-validate-file-wrapper {
  position: absolute !important;
  background: none;

  .MuiPaper-root {
    width: 100%;
    min-height: 70vh;
    max-width: 1094px;
    max-height: max-content;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .MuiDialog-container {
    height: auto;
  }
  .MuiDialog-paper {
    margin: 10px;
  }

  .MuiTable-root {
    min-width: 700px;
  }

  .checbox-filter-item {
    display: flex;
    align-items: center;
    margin: 7px 0;
    input {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .file-data {
    margin-top: 30px;
    display: flex;
    @include fontProps("Manrope", 14px, bold, #404040, 19px);

    div {
      &:not(:last-child) {
        margin-right: 30px;
      }

      span {
        margin-left: 5px;
        @include fontProps("Manrope", 14px, 800, #000000, 19px);
      }
    }
  }

  .confirmation-message {
    @include fontProps("Manrope", 16px, 600, #404040, 19px);
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .buttons-actions {
    display: flex;
    max-width: 260px;
    margin: 20px auto;
    justify-content: space-between;

    .payment-options-wrapper {
      width: 120px;

      &.long-button {
        width: 400px;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .transferences-content-wrapper {
    margin-top: 30px;
    max-height: 350px;
    min-height: 350px;
    overflow: auto;
  }

  .table-container {
    .transferences-table-head {
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      .table-head-cell {
        border-bottom: none;
        padding: 11px 20px;
        @include fontProps("Manrope", 14px, bold, #404040, 19px);
      }
    }
    .transferences-table-body {
      .transferences-notification {
        margin-top: 15px;
        @include fontProps("Manrope", 13px, 500, #404040, 18px);
      }
      .transferences-body-row {
        cursor: pointer;
        .table-body-cell {
          padding: 20px 16px;
          @include fontProps("Manrope", 13px, 500, #404040, 18px);

          img {
            width: 20px;
            border-radius: 50px;
            margin: 0 auto;
          }
        }
        &:hover {
          background: #fafafa;
        }
      }
    }
  }

  .validate-file-content {
    padding: 0 0 60px 0 !important;

    button:disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
    }

    .input-item {
      max-width: 250px;
      width: 100%;
      padding: 10px 15px;
      background: rgba(245, 245, 245, 0.3);
      border: 1px solid rgba(200, 200, 200, 0.5);
      border-radius: 8px;
      font-family: "Manrope";
      font-weight: 600;
      font-size: 14px;
      color: #1a203d;
      line-height: 20px;
    }

    .loading-content {
      .spinner-wrapper {
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .validate-file-header-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 20px 60px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);

      @media screen and(max-width: 900px) {
        padding: 10px 20px;
      }

      .validate-file-actions-wrapper {
        display: flex;
        align-items: center;
        @media screen and(max-width: 900px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .validate-file-title {
          @include fontProps("Manrope", 16px, 600, #404040, 33px);
        }
      }
      .close-img-wrapper {
        cursor: pointer;
      }
    }
    .spinner-wrapper,
    .error-wrapper,
    .success-wrapper {
      margin-top: 50px;
    }
    .main-content-wrapper {
      margin-top: 17px;
      display: flex;
      padding: 0 60px;
      flex-direction: column;
      // border-bottom: 1px solid grey;

      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }

      .payment-bths-wrapper {
        margin-top: 0;
      }

      .bth-wrapper.make-payment-bth-wrapper {
        width: 100%;
      }

      .input-container {
        display: flex;

        .payment-bths-wrapper {
          margin-left: 20px;
        }
      }

      .user-loan-info {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .user-info,
        .loan-info,
        .payment-info {
          div {
            margin-bottom: 15px;
            @include fontProps("Manrope", 16px, 600, #404040, 33px);
          }

          span {
            display: block;
            @include fontProps("Manrope", 14px, 600, #404040, 33px);
          }
        }

        .payment-info {
          input {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  @include scrollBar();
}
