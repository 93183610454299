@import "../../../../../../styles//mixins.scss";

body {
  overflow: visible !important;
}

.gender-value {
  .radio-title {
    padding: 0 10px;
    @include fontProps("Manrope", 16px, 600, #000000, 22px);
    .start {
      color: red;
    }
  }
  .radio-group-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 250px;

    @media only screen and (max-width: 400px) {
      max-width: 100%;
      margin-top: 10px;
    }

    .female-radio-item {
      margin-left: 18px;
    }

    .radio-group-item-wrapper {
      display: inline-flex;
      align-items: center;
      .input-item {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        margin-top: 0;
      }

      label {
        margin-bottom: 0;
      }
    }
  }
}

.modal-inbox-item-wrapper {
  position: absolute !important;
  background: none;

  input.input-item,
  select,
  textarea,
  .MuiFormControl-root {
    width: 100%;
    max-width: 232px;
    padding: 10px 15px;
    background: rgba(245, 245, 245, 0.3);
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 8px;
    @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
    .MuiFilledInput-root {
      background: transparent;
    }
    .MuiSelect-root {
      padding: 0;
    }
    .MuiInputBase-root::before {
      border: none;
    }
    &:focus {
      outline: none;
      border: 2px solid #696565;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      margin-top: 6px;
    }
  }

  .MuiPaper-root {
    width: 100%;
    max-width: 1094px;
    max-height: max-content;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .MuiDialog-paper {
    margin: 10px;
  }
  .MuiDialog-container {
    height: auto;
  }

  .reference-title {
    padding-right: 10px;
    @include fontProps("Manrope", 16px, 600, #404040, 19px);
    margin-bottom: 15px;
  }

  .loan-department-form-control {
    min-width: 200px;
  }

  .inbox-item-content {
    padding: 0 0 60px 0 !important;

    .MuiInputBase-input {
      padding: 0;
    }

    .description-value {
      display: flex;

      div:first-child {
        margin-right: 10px;
      }
    }

    &.loading-content {
      height: 97vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .inbox-item-header-wrapper {
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      display: flex;
      justify-content: space-between;
      padding: 20px 60px;
      @media screen and(max-width: 900px) {
        padding: 10px 20px;
        flex-direction: column;
      }

      .user-name-container {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .user-name {
          @include fontProps("Manrope", 24px, 600, #404040, 33px);
        }

        .reputation {
          @include fontProps("Manrope", 16px, 600, #ffffff, 33px);
          margin-right: 60px;
          padding: 5px 15px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      .inbox-item-header-navigation {
        display: flex;
        align-items: center;
        @media screen and(max-width: 450px) {
          margin-right: 0px;
          order: -1;
          justify-content: space-between;
          margin-bottom: 10px;
        }
        .switch-item-icons-wrapper {
          display: flex;
          margin-right: 50px;
          @media screen and(max-width: 450px) {
            margin-right: 0px;
          }
          .switch-item-bth {
            &.prev-item {
              margin-right: 40px;
            }
            &.next-item {
              transform: rotate(180deg);
            }
            &.disabled {
              opacity: 0.2;
              pointer-events: none;
            }
            &.active {
              cursor: pointer;
            }
          }
        }
        .close-img-wrapper {
          cursor: pointer;
        }
      }
    }
    .spinner-wrapper,
    .error-wrapper,
    .success-wrapper {
      margin-top: 50px;
      .spinner-text {
        font-size: 13px;
      }
    }
    .main-content-wrapper {
      margin-top: 17px;
      display: flex;
      padding: 0 60px;
      border-bottom: 1px solid grey;
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }
      .left-block-wrapper,
      .right-block-wrapper {
        width: 50%;
        padding-bottom: 60px;
        @media screen and(max-width: 900px) {
          width: 100%;
          padding-bottom: 30px;
        }
        .user-info-block-wrapper {
          .block-title {
            @include fontProps("Manrope", 16px, bold, #404040, 22px);
          }
          .block-content-wrapper {
            padding-right: 20px;
            margin-top: 16px;
            @media screen and(max-width: 450px) {
              padding-right: 0px;
              align-items: flex-start;
            }
            .info-item {
              padding: 10px 0;
              display: flex;
              justify-content: space-between;
              @media screen and(max-width: 450px) {
                flex-direction: column;
                justify-content: flex-start;
              }
              .title {
                padding-right: 10px;
                @include fontProps("Manrope", 14px, 600, #404040, 19px);
              }
              .value {
                @include fontProps("Manrope", 14px, 600, #404040, 19px);
              }
              &:not(:first-child) {
                border-top: 1px solid grey;
              }
            }
          }
        }
      }
      .left-block-wrapper {
        border-right: 1px solid grey;
        @media screen and(max-width: 900px) {
          border-right: none;
        }
        .user-photos-block-wrapper {
          padding-bottom: 36px;
          border-bottom: 1px solid grey;
          .photos-list-wrapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            @media screen and(max-width: 680px) {
              flex-direction: column;
              padding-right: 0px;
            }
            .photo-item-wrapper {
              width: 30%;
              // background: linear-gradient(90deg, #ececfe, #fdecf6);
              .spinner-wrapper {
                margin-top: 0;
              }
              @media screen and(max-width:680px) {
                margin-top: 10px;
                width: 100%;
              }
              img {
                width: 100%;
                border-radius: 8px;
                cursor: pointer;
              }
            }
          }
        }
        .user-contact-info-block-wrapper {
          margin-top: 32px;
        }
        .user-personal-info-block-wrapper {
          margin-top: 40px;
        }
      }
      .right-block-wrapper {
        padding-left: 20px;
        @media screen and(max-width: 900px) {
          padding-left: 0px;
        }
        .user-adrress-info-block-wrapper {
          margin-top: 40px;
        }
      }
    }
    .loan-content-wrapper {
      padding: 10px 60px;
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 10px 20px;
      }
      border-bottom: 1px solid grey;
      .loan-item-sub-info {
        display: flex;
        align-items: center;

        .title {
          margin-right: 10px;
        }
      }
      .loan-item-info-wrapper {
        padding: 15px 0;
        display: flex;
        align-items: center;
        @media screen and(max-width: 450px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .title {
          width: 150px;
          @include fontProps("Manrope", 14px, bold, #000000, 19px);
        }
        .value {
          @include fontProps("Manrope", 14px, 600, #404040, 19px);
          width: 80%;
        }
      }
    }
    .loans-history-content-wrapper {
      padding: 0 60px;
      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }
      .loans-history-table-title {
        margin-top: 30px;
        @include fontProps("Manrope", 20px, 600, #404040, 27px);
      }
      .table-container {
        margin-top: 30px;

        .indicator {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: block;
        }

        .MuiTable-root {
          min-width: 850px;
        }

        .loan-history-table-head {
          background: linear-gradient(90deg, #ececfe, #fdecf6);
          .table-head-cell {
            border-bottom: none;
            padding: 11px 20px;
            @include fontProps("Manrope", 14px, bold, #404040, 19px);
          }
        }
        .empty-data-notifaction {
          margin-top: 5px;
          margin-left: 15px;
          @include fontProps("Manrope", 14px, 500, #404040, 19px);
        }
        .loan-history-body-row {
          cursor: pointer;

          .table-body-cell {
            padding: 20px 16px;
            @include fontProps("Manrope", 13px, 500, #404040, 18px);
          }
          &:hover {
            background: #fafafa;
          }
        }
      }
    }

    .editor-wrapper {
      margin-top: 20px;
      border-top: 1px solid grey;

      .title {
        font-family: "Manrope";
        font-weight: bold;
        font-size: 14px;
        color: #000000;
        line-height: 19px;
        padding-left: 60px;
        margin-top: 20px;
      }

      .editor-container {
        margin: 10px 60px auto;
        border: 1px solid rgba(200, 200, 200, 0.5);

        .editorClassName {
          padding: 0 20px;
          height: 300px;
        }

        .toolbarClassName {
          border: none;
          border-bottom: 1px solid #f1f1f1;
        }
      }
    }

    .bths-actions-wrapper {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      @media screen and(max-width: 790px) {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
      }
      .bth-action-wrapper {
        @media screen and(max-width: 790px) {
          margin-top: 10px;
        }
        .bth {
          background: none;
          border: none;
          padding: 10px 50px;
          border-radius: 8px;
          @media screen and(max-width: 790px) {
            width: 250px;
          }
          @include fontProps("Manrope", 14px, 600, #ffffff, 18px);
          &.bth-photo-not-match {
            color: #000000;
            background: #e8e5e9;
          }
          &.bth-deny {
            background: #eb5757;
          }
          &.bth-accept {
            background: #219653;
          }
          &.bth-block {
            background: #000000;
          }
          &.bth-accept-no-decision {
            background: #ffbf00;
            color: black;
          }
        }
        &:not(:last-child) {
          margin-right: 16px;
          @media screen and(max-width: 790px) {
            margin-right: 0px;
          }
        }
      }

      button:disabled {
        opacity: 0.5;
      }
    }
  }
  @include scrollBar();
}
