@font-face {
  font-family: "Mont";
  src: url("Mont-Regular.eot");
  src: local("Mont Regular"), local("Mont-Regular"),
    url("Mont-Regular.eot?#iefix") format("embedded-opentype"),
    url("Mont-Regular.woff") format("woff"),
    url("Mont-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
