@import "../../../../../../../styles//mixins.scss";

.loan-status-wrapper {
  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .status-indicator-wrapper {
    .title-status {
      margin-left: 10px;
      @include fontProps("Manrope", 18px, 500, #eb5757, 28px);
    }

    &.blocked-loan {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 8px;

      margin-top: 10px;
      background: transparent;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
      }

      span:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: #f7bcbc;
        img {
          width: 26px;
          opacity: 0.5;
        }
      }
      .message,
      .aditional-message {
        @include fontProps("Manrope", 16px, 500, #000000, 28px);
        margin-left: 12px;

        @media only screen and (max-width: 768px) {
          text-align: center;
          margin-top: 16px;
          margin-left: 0;
        }
      }

      .aditional-message {
        margin-top: 10px;
      }
    }
  }
}
