@import "../../../../../../../styles//mixins.scss";

.loan-status-wrapper {
  .suspended-date {
    margin-top: 25px;
  }

  .status-indicator-wrapper.refused-loan {
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    // width: max-content;
    margin-top: 10px;
    background: transparent;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
    }
    span:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #f7bcbc;
      img {
        width: 26px;
        opacity: 0.5;
      }
    }
    span:last-child {
      @include fontProps("Manrope", 16px, 500, #000000, 28px);
      margin-left: 12px;
      width: 80%;
      @media only screen and (max-width: 768px) {
        width: 90%;
        text-align: center;
        margin-top: 16px;
        margin-left: 0;
      }
    }
  }
}
