@import '../../../../../styles/mixins.scss';

.inbox-tittle-wrapper {
  margin: 0 50px;
  display: flex;
  justify-content: space-between;
  .title {
    @include fontProps('Manrope', 24px, 600, #404040, 33px);
  }
  @media screen and(max-width: 550px) {
    margin: 0 20px;
  }
}
