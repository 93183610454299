@import "../../../styles/mixins.scss";

.modal-process-wrapper {
  .MuiPaper-root {
    width: 100%;
    max-width: 360px;
  }
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .register-reminder {
    margin-top: 20px;
    color: #eb5757;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
  .modal-process-content {
    padding: 40px !important;
    position: relative;
    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    .handle-pesponse-wrapper {
      margin-top: 10px;
    }
  }
}
