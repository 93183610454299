@import "../../../../../../styles//mixins.scss";

body {
  overflow: visible !important;
}

.modal-emergency-item-wrapper {
  position: absolute !important;
  background: none;

  .loan-container {
    max-width: 700px;
    margin: auto;
    padding: 30px 0px;
  }

  .MuiPaper-root {
    width: 100%;
    min-height: 70vh;
    max-width: 1094px;
    max-height: max-content;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .MuiDialog-container {
    height: auto;
  }
  .MuiDialog-paper {
    margin: 10px;
  }

  .MuiTable-root {
    min-width: 700px;
  }

  .loan-data {
    margin-top: 30px;
    @include fontProps("Manrope", 14px, bold, #404040, 19px);

    div:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .buttons-actions {
    max-width: 250px;
    margin: 20px auto;

    .payment-options-wrapper {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .table-container {
    margin-top: 30px;
    .loans-history-table-head {
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      .table-head-cell {
        border-bottom: none;
        padding: 11px 20px;
        @include fontProps("Manrope", 14px, bold, #404040, 19px);
      }
    }
    .loans-history-table-body {
      .loans-history-notification {
        margin-top: 15px;
        @include fontProps("Manrope", 13px, 500, #404040, 18px);
      }
      .loans-history-body-row {
        cursor: pointer;
        .table-body-cell {
          padding: 20px 16px;
          @include fontProps("Manrope", 13px, 500, #404040, 18px);
        }
        &:hover {
          background: #fafafa;
        }
      }
    }
  }

  .emergency-item-content {
    padding: 0 0 60px 0 !important;

    button:disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
    }

    .input-item {
      max-width: 250px;
      width: 100%;
      padding: 10px 15px;
      background: rgba(245, 245, 245, 0.3);
      border: 1px solid rgba(200, 200, 200, 0.5);
      border-radius: 8px;
      font-family: "Manrope";
      font-weight: 600;
      font-size: 14px;
      color: #1a203d;
      line-height: 20px;
    }

    .loading-content {
      .spinner-wrapper {
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .emergency-item-header-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 20px 60px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);

      @media screen and(max-width: 900px) {
        padding: 10px 20px;
      }

      .emergency-item-actions-wrapper {
        display: flex;
        align-items: center;
        @media screen and(max-width: 900px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .emergency-item-title {
          @include fontProps("Manrope", 16px, 600, #404040, 33px);
        }
      }
      .close-img-wrapper {
        cursor: pointer;
      }
    }
    .spinner-wrapper,
    .error-wrapper,
    .success-wrapper {
      margin-top: 50px;
    }
    .main-content-wrapper {
      margin-top: 17px;
      display: flex;
      padding: 0 60px;
      flex-direction: column;
      // border-bottom: 1px solid grey;

      @media screen and(max-width: 900px) {
        flex-direction: column;
        padding: 0 20px;
      }

      .payment-bths-wrapper {
        margin-top: 0;
      }

      .bth-wrapper.make-payment-bth-wrapper {
        width: 100%;
      }

      .input-container {
        display: flex;

        .payment-bths-wrapper {
          margin-left: 20px;
        }
      }

      .user-loan-info {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .user-info,
        .loan-info,
        .payment-info {
          div {
            margin-bottom: 15px;
            @include fontProps("Manrope", 16px, 600, #404040, 33px);
          }

          span {
            display: block;
            @include fontProps("Manrope", 14px, 600, #404040, 33px);
          }
        }

        .payment-info {
          input {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  @include scrollBar();
}
