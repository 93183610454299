@import "../../../../../../../styles/mixins.scss";

.dismiss-bths-wrapper {
  margin-top: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;

  /*   @media only screen and (max-width: 768px) {
    flex-direction: column;
  } */
  .bth-wrapper {
    width: 45%;
    @include bthGradiendBorderWrapper();
    @media only screen and (max-width: 768px) {
      width: 100%;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    .bth {
      padding: 10px;
      width: 100%;
      @include bthWithGradiendBorder();
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
    }
  }
}
