@import '../../../../styles/mixins.scss';

.help-tab-wrapper {
  @include userProfileTabPannelWrapper(
    100%,
    650px,
    100%,
    35px,
    8px,
    rgba(255, 255, 255, 0.5)
  );
  .tab-title {
    @include fontProps('Manrope', 24px, 600, #1a203d, 33px);
    @media only screen and (max-width: 768px) {
      @include fontProps('Manrope', 20px, 600, #1a203d, 27px);
      text-align: center;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      padding: 24px 0;
    }
  }
  .question-accordions__wrapper {
    margin-top: 32px;
    @media only screen and (max-width: 768px) {
      margin-top: 0;
    }
    .accordion-item-wrapper {
      box-shadow: none;
      background: none;
      &:not(:first-child) {
        border-top: 1px solid #e0e0e0;
      }
      .heading-accordion {
        @include fontProps('Manrope', 18px, 600, #404040, 25px);
        padding: 24px 0;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
          @include fontProps('Manrope', 16px, 600, #404040, 22px);
        }
        .accordion-icon-wrapper-open {
          img {
            transform: rotate(-180deg);
          }
        }
      }
      .body-accordion {
        @include fontProps('Manrope', 12px, 500, #404040, 22px);
        transition: all 0.7s ease;
        overflow: hidden;
        &.body-accordion-open {
          max-height: 300px;
        }
        &.body-accordion-close {
          max-height: 0;
        }
      }
    }
  }
}
