@import "../../../../../styles/mixins.scss";

.modal-contact-us-wrapper {
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .MuiDialog-container {
    .MuiPaper-root {
      max-width: 610px;
      width: 100%;
      padding: 40px;
      @media only screen and (max-width: 768px) {
        margin: 16px;
        padding: 30px 0 0 0;
      }
      .content-dialog {
        padding: 5px;
        margin: 10px;
        .close-img {
          position: absolute;
          right: 30px;
          top: 30px;
          cursor: pointer;
          @media only screen and (max-width: 768px) {
            top: 14px;
            right: 14px;
          }
        }
        .form-title {
          @include fontProps("Manrope", 20px, 600, #1a203d, 27px);
          text-align: center;
        }
        .form-contact-us {
          .user-info {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;

            span {
              @include fontProps("Manrope", 16px, 600, #1a203d, 20px);
            }
          }
          .form-item {
            input.input-item,
            textarea {
              width: 100%;
              padding: 10px 15px;
              background: rgba(245, 245, 245, 0.3);
              border: 1px solid rgba(200, 200, 200, 0.5);
              border-radius: 8px;
              @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
              &:focus {
                outline: none;
              }
            }
            textarea {
              height: 260px;
            }
          }
          .email-item {
            margin-top: 25px;
          }
          .message-item {
            margin-top: 15px;
          }
          .attach-files-block-wrapper {
            margin-top: 25px;
            .attach-file-bth-wrapper {
              label {
                margin: 0;
                display: flex;
                justify-content: center;
                width: 100%;
                max-width: 293px;
                border: 1px solid #000000;
                padding: 6px;
                cursor: pointer;
                border-radius: 8px;
                .clip-img {
                  margin-right: 15px;
                  .text {
                    @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
                  }
                }
              }
              .attach-files-list.active {
                width: 100%;
                margin-top: 22px;
                .attach-file-item-wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 17px;
                  .attach-file-name,
                  .attach-file-delete-bth {
                    padding: 0 5px;
                    width: 85%;
                  }
                  .attach-file-name {
                    @include fontProps("Manrope", 14px, 600, #000000, 19px);
                  }
                  .attach-file-delete-bth {
                    @include fontProps("Manrope", 14px, 600, #000000, 19px);
                    background: none;
                    border: none;
                    width: auto;
                    padding: 10px;
                  }
                }
              }
            }
          }
          .bth-submit-wrapper {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            .bth-submit {
              border: none;
              padding: 11px;
              width: 100%;
              max-width: 289px;
              @include gradiendBgWithHoverEffect();
              @include fontProps("Manrope", 14px, 600, #ffffff, 19px);
              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }
            }
          }
        }
      }
    }
  }
  @include scrollBar;
}
