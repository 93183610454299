@import "../../../../../styles/mixins.scss";

.dashboard-content-layout-wrapper {
  max-width: 1200px;

  .charts-container {
    display: flex;
    min-height: 460px;
  }

  .charts {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-top: 40px;
  }

  .chart-item {
    padding: 10px;
    padding: 5px;
    width: 100%;
  }

  .filter-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .react-datepicker__close-icon::after {
      background-color: #eb5757;
    }

    .input-item {
      width: 250px;
      padding: 10px 15px;
      background: rgba(245, 245, 245, 0.3);
      border: 1px solid rgba(200, 200, 200, 0.5);
      border-radius: 8px;
      font-family: "Manrope";
      font-weight: 600;
      font-size: 14px;
      color: #1a203d;
      line-height: 20px;

      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-right: 15px;
      @include fontProps("Manrope", 14px, bold, #404040, 19px);

      span {
        margin-bottom: 10px;
      }
    }

    .input-date-container {
      position: relative;

      .input-calendar {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 5px;
      }

      img {
        cursor: pointer;
      }
    }
  }
}
