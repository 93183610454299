@import '../../../styles/mixins.scss';

.modal-info-wrapper {
  .MuiPaper-root {
    width: 100%;
    max-width: 360px;
  }
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .modal-info-content {
    padding: 40px !important;
    position: relative;
    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    .info-text {
      text-align: center;
      margin-top: 10px;
      @include fontProps('Manrope', 18px, bold, #404040, 25px);
    }
  }
}
