// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// .navbar-custom {
//   background-color: rgba(0, 0, 0, 0);
// }

/* custom global CSS */
@import "./fonts/font.scss";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #fefefefe;
}

.lazy-load-image-background {
  display: contents !important;
}

.general-loading {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    #424770;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner-text {
    color: #fefefefe !important;
  }
}

.container-width {
  width: 100%;
  max-width: 1232px;
  margin: auto;
  padding: 0 21px;
  /* box-sizing: border-box; */
}

button:focus {
  outline: none;
}

a.nav_link {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
}

iframe {
  display: none;
}
