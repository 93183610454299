@import "../../../../../../../styles//mixins.scss";

.loan-status-wrapper.successfully-closed-loan {
  .status-indicator-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    @include statusIndicatorWrapper(#f6fbf8);
    span:first-child {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #219653;
    }
    span:last-child {
      @include fontProps("Manrope", 16px, bold, #1a203d, 22px);
      margin-left: 12px;
    }
  }
  .consideration-notification-wrapper {
    margin-top: 16px;
    .notification-text {
      @include fontProps("Manrope", 16px, 500, #404040, 28px);
      max-width: 420px;
    }
  }
}
