@import "../../../../../../../styles/mixins.scss";

.loan-status-wrapper {
  .status-indicator-wrapper.consideration-loan {
    display: flex;
    align-items: center;
    width: 100%;
    @include statusIndicatorWrapper(#fefbf1);
    span:first-child {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #f2c94c;
    }
    span:last-child {
      @include fontProps("Manrope", 16px, bold, #1a203d, 22px);
      margin-left: 12px;
    }
  }
  .consideration-notification-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 16px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    .notification-text {
      @include fontProps("Manrope", 14px, 500, #404040, 24px);
      width: 70%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }
    }
    .notification-timer-wrapper {
      @media only screen and (max-width: 768px) {
        margin-top: 16px;
      }
      .notification-timer {
        background: linear-gradient(90deg, #ececfe, #fdecf6);
        padding: 6px 14px;
        border-radius: 8px;
        @include fontProps("Manrope", 18px, 600, #404040, 25px);
      }
      .timer-info {
        text-align: right;
        @include fontProps("Manrope", 14px, 600, #eb5757, 20px);
      }
    }
  }
}
