@import "../../../../../styles/mixins.scss";

.payments-header-wrapper {
  margin-left: 50px;
  @media screen and(max-width: 550px) {
    margin-left: 20px;
  }
  .payments-title-wrapper {
    @include fontProps("Manrope", 24px, 600, #404040, 33px);
  }
}
