@import "../../../../../../../styles/mixins.scss";
.payment-bths-wrapper {
  margin-top: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  .bth-wrapper {
    width: 45%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    .bth {
      padding: 10px;
      width: 100%;
      border: none;
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
      &.make-payment-bth {
        @include gradiendBgWithHoverEffect();
      }
    }
    &.prolong-contract-bth-wrapper {
      @include bthGradiendBorderWrapper();
      .prolong-contract-bth {
        @include bthWithGradiendBorder();
      }
    }
  }
}

.payment-options-wrapper {
  .notication-text {
    @include fontProps("Manrope", 14px, 500, #eb5757, 22px);
    margin-top: 20px;
  }
}
