@import "../../../../../../styles//mixins.scss";
.informative-step {
  padding-bottom: 30px;

  .additional-message {
    max-width: 60%;
    margin: 30px auto 0;
    text-align: justify;
    @include fontProps("Manrope", 16px, 600, #424770, 23px);

    @media only screen and (max-width: 768px) {
      max-width: 90%;
    }
  }

  .title-wrappper {
    background: #ffffff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 30px;
    padding: 60px 40px 40px 40px;
    text-align: center;
    @media only screen and (max-width: 768px) {
      padding: 15px;
      box-shadow: none;
      border-radius: 0;
    }
    p:first-child {
      @include fontProps("Manrope", 32px, 600, #424770, 41px);
      @media only screen and (max-width: 768px) {
        font-size: 22px;
        line-height: 36px;
      }
    }
    p:last-child {
      @include fontProps("Manrope", 22px, 600, #424770, 30px);
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
  .necessary-items-wrappper {
    display: flex;
    justify-content: space-between;
    max-width: 850px;
    margin: 60px auto 0 auto;
    padding: 0 40px 0 40px;
    @media only screen and (max-width: 768px) {
      padding: 0 15px 0 15px;
      max-width: 100%;
      margin: 0 auto 0 auto;
      flex-direction: column;
    }
    .necessary-item {
      width: 100%;
      max-width: 216px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      padding: 24px;
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
      }
      .necessary-item-title {
        @include fontProps("Manrope", 20px, 600, #424770, 27px);
        text-align: center;
        @media only screen and (max-width: 768px) {
          text-align: left;
          &.desktop {
            display: none;
          }
        }
      }
      .necessary-item-icon-wrapper {
        margin-top: 15px;
        text-align: center;
        @media only screen and (max-width: 768px) {
          margin-top: 0;
          text-align: left;
        }
      }
      .necessary-item-description {
        @include fontProps("Manrope", 16px, 500, #424770, 24px);
        margin-top: 15px;
        text-align: center;
        @media only screen and (max-width: 768px) {
          text-align: left;
          margin-top: 0;
          &.desktop {
            display: none;
          }
        }
      }
      .text-mobile {
        @media only screen and (min-width: 768px) {
          display: none;
        }
        @media only screen and (max-width: 768px) {
          display: block;
          margin-left: 20px;
        }
      }
    }
  }
  .decription {
    @include fontProps("Manrope", 20px, 500, #424770, 24px);
    margin-top: 40px;
    text-align: center;
    padding: 0 40px;
    @media only screen and (max-width: 768px) {
      padding: 0 15px;
      margin-top: 30px;
    }
  }

  .bth-action {
    display: flex;
    justify-content: center;
    margin-top: 42px;
    padding: 0 40px;
    @media only screen and (max-width: 768px) {
      padding: 0 15px;
      margin-top: 0px;
    }
    button {
      width: 100%;
      max-width: 246px;
      padding: 13px;
      border: none;
      @include gradiendBgWithHoverEffect();
      @include fontProps("Manrope", 18px, 600, #ffffff, 25px);
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        margin-top: 30px;
      }
    }
  }
}
