@import '../../../../../styles/mixins.scss';

.dashboard-header-wrapper {
  margin-left: 50px;
  .dashboard-title-wrapper {
    @include fontProps('Manrope', 24px, 600, #404040, 33px);
  }
  @media screen and(max-width: 550px) {
    margin-left: 20px;
  }
}
