@import "../../../../../../../styles//mixins.scss";

.modal-edit-user-wrapper {
  .spinner-wrapper,
  .error-wrapper {
    margin-top: 50px;
  }

  form.form-edit-user {
    // padding: 40px;
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;

    @media only screen and (max-width: 768px) {
      padding: 15px;
    }

    .form-section {
      padding: 30px 0px;
    }

    .form-section-title {
      @include fontProps("Manrope", 16px, 600, #404040, 27px);
    }

    .required-fields {
      width: 100%;
      padding: 0 10px;

      @include fontProps("Manrope", 16px, 600, #000000, 22px);
      @media only screen and (max-width: 768px) {
        padding: 0;
      }
      .start {
        color: red;
      }
    }

    .form-item {
      // width: 45%;
      margin-top: 32px;

      @media only screen and (max-width: 768px) {
        width: 100%;
        &.type-employment-item {
          order: 1;
        }
        &.institution-name-item {
          order: 2;
        }
        &.company-name-item {
          order: 2;
        }
        &.company-phone-item {
          order: 3;
        }
        &.netIncome-item {
          order: 4;
        }
        &.additional-income-item {
          order: 5;
        }
        &.other-loans-item {
          order: 6;
        }
        &.loan-purpose-item {
          order: 7;
        }
        &.loan-purpose-item {
          order: 7;
        }
        &.description-item {
          order: 8;
        }
      }

      .flex-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @media only screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
      }

      label.label-item {
        @include fontProps("Manrope", 16px, 600, #000000, 22px);
        margin: 0;
        padding: 0 10px;
        @media only screen and (max-width: 768px) {
          padding: 0;
          width: 100%;
        }
        .start {
          color: red;
        }
      }
      input.input-item,
      select,
      textarea,
      .MuiFormControl-root {
        width: 100%;
        max-width: 210px;
        padding: 10px 15px;
        background: rgba(245, 245, 245, 0.3);
        border: 1px solid rgba(200, 200, 200, 0.5);
        border-radius: 8px;
        @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
        .MuiFilledInput-root {
          background: transparent;
        }
        .MuiSelect-root {
          padding: 0;
        }
        .MuiInputBase-root::before {
          border: none;
        }
        &:focus {
          outline: none;
          border: 2px solid #696565;
        }
        @media only screen and (max-width: 768px) {
          max-width: 100%;
          margin-top: 6px;
        }
      }
      .error-text {
        text-align: right;
      }
      .loan-purpose-form-control {
        width: 100%;
        max-width: 435px;
      }

      #netIncome,
      #additionalIncome,
      #otherLoans {
        padding-left: 25px;
      }
      .input-item-wrapper {
        position: relative;
        //width: 100%;
        max-width: 100%;

        @media only screen and (max-width: 768px) {
          max-width: 100%;
          margin-top: 6px;
        }
      }
      input.input-item {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      img.dolar-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        @media only screen and (max-width: 768px) {
          top: 55%;
        }
      }
      &.company-phone-item {
        .input-item {
          width: 100%;
          max-width: 232px;
          padding: 10px 15px;
          background: rgba(245, 245, 245, 0.3);
          border: 1px solid rgba(200, 200, 200, 0.5);
          border-radius: 8px;
          font-family: "Manrope";
          font-weight: 600;
          font-size: 14px;
          color: #1a203d;
          line-height: 20px;
          @media only screen and (max-width: 768px) {
            max-width: 100%;
            margin-top: 6px;
          }
          .MuiInputBase-root::before {
            border: none !important;
          }

          .MuiInputBase-input {
            padding: 0;
          }
        }
        &:focus {
          outline: none;
          border: 2px solid #696565;
        }
      }
      &.loan-purpose-item {
        width: 100%;
        .error-text {
          padding: 0 10px;
          text-align: left;
        }
        .flex-container {
          justify-content: flex-start;
          label {
            width: 100%;
            max-width: 150px;
          }
          select#loanPurpose {
            width: 100%;
            max-width: 435px;
          }
        }
      }
      &.description-item {
        width: 100%;
        @include scrollBar;
        .error-text {
          padding: 0 10px;
          text-align: left;
        }
        .flex-container {
          justify-content: flex-start;
          label {
            width: 100%;
            max-width: 150px;
          }
          textarea {
            width: 100%;
            max-width: 435px;
            height: 100px;
            resize: none;
          }
        }
      }
    }

    .bths-wrapper {
      order: 9;
    }

    .grey-line {
      width: 100%;
      background: #e0e0e0;
      height: 1px;
      margin-top: 38px;
    }

    .bths-wrapper {
      width: 100%;
      margin-top: 60px;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin-top: 0;
      }
      .bth-action {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 246px;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
          margin-top: 30px;
        }
        button {
          width: 100%;
          max-width: 246px;
          padding: 13px;
          border: none;
          background: none;
          background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
            #ff7472;
          box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
          border-radius: 8px;
          @include fontProps("Manrope", 18px, 600, #ffffff, 25px);
          @include activationFormBthEffect;
          @media only screen and (max-width: 768px) {
            max-width: 100%;
          }
        }
      }
      .bth-back-wrapper {
        margin-right: 10px;
        button {
          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
              ),
              linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
          }
        }
      }
    }
  }

  .textarea-wrapper {
    .textarea-title {
      text-align: center;
      @include fontProps("Manrope", 24px, 600, #404040, 33px);
    }
    textarea {
      width: 100%;
      border: 1px solid #424770;
      border-radius: 8px;
      height: 120px;
      padding: 15px;
      resize: none;
      &:focus {
        outline: none;
        border: 1px solid #000000;
      }
    }
  }

  .MuiPaper-root {
    width: 100%;
    max-width: 1000px;
    height: 80vh;
  }
  .MuiDialog-paper {
    margin: 10px;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .edit-user-content {
    padding: 0 0 30px 0 !important;
    @media screen and(max-width: 720px) {
      padding: 0 0 20px 0 !important;
    }

    .rigth-section,
    .left-section {
      padding: 0 10px;
    }

    .modify-limit-header-wrapper {
      padding: 22px 42px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      display: flex;
      justify-content: space-between;
      @media screen and(max-width: 720px) {
        padding: 10px 20px;
        flex-wrap: wrap;
      }

      .contract-id {
        @include fontProps("Manrope", 20px, 600, #404040, 27px);
        @media screen and(max-width: 720px) {
          width: 100%;
          order: 1;
          margin-top: 15px;
        }
      }
      .user-name-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media screen and(max-width: 720px) {
          max-width: 80%;
          align-items: flex-start;
        }
        .user-name {
          @include fontProps("Manrope", 16px, 600, #404040, 22px);
        }
        .user-name-icon-wrapper {
          margin-left: 15px;
        }
      }
    }

    .buttons-container {
      display: flex;
      width: 70%;
      justify-content: center;
      margin: auto;

      button:disabled {
        opacity: 0.3;
      }

      .dismiss-bths-wrapper,
      .payment-bths-wrapper {
        width: 100%;

        padding: 0 10px;
      }

      .bth-wrapper {
        width: 100%;
      }
    }

    .main-content-wrapper {
      padding: 0 30px;
      // margin-top: 50px;

      @media screen and(max-width: 720px) {
        padding: 0 20px;
        margin-top: 30px;
      }

      .change-limit-title {
        text-align: center;
        @include fontProps("Manrope", 18px, 600, #404040, 22px);
      }

      .change-limit-subtitle {
        text-align: center;
        margin: 15px 0;
        @include fontProps("Manrope", 14px, 600, #404040, 22px);
      }

      .radio-title {
        padding: 0 10px;
        @include fontProps("Manrope", 16px, 600, #000000, 22px);
        .start {
          color: red;
        }
      }
      .radio-group-wrapper {
        display: flex;

        @media only screen and (max-width: 400px) {
          max-width: 100%;
          margin-top: 10px;
        }
        .radio-group-item-wrapper {
          display: flex;
          align-items: center;

          label {
            margin: 0;
            @include fontProps("Manrope", 14px, 600, #404040, 22px);
          }

          .input-item {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-top: 0;
          }
        }
      }
    }
  }
  @include scrollBar();
}
