@import "../../../../../../styles/mixins.scss";

.question-icon-wrapper {
  margin-left: 10px;
  position: relative;
  @include questionIconWrapper();
  .question-icon-description {
    display: none;
    position: absolute;
    left: 250%;
    top: -65px;
    // width: 100%;
    // max-width: 300px;
    width: 300px;
    padding: 24px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    &::before {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 20px 15px 0;
      border-color: transparent #ffffff transparent transparent;
      display: inline-block;
      position: absolute;
      top: 57px;
      left: -20px;
    }
    &.open {
      display: block;
    }
  }
}

.loan-payment-options-wrapper {
  @include userProfileTabPannelWrapper(
    100%,
    880px,
    100%,
    35px,
    8px,
    rgba(255, 255, 255, 0.5)
  );
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  .payment-block-header {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      padding: 24px 0;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    @media only screen and (max-width: 576px) {
      flex-direction: column;

      .header-desciption {
        margin-bottom: 15px;
      }
    }

    @media (min-width: 768px) and (max-width: 880px) {
      flex-direction: column;

      .header-loan-amount-return {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    .header-desciption {
      display: flex;
      align-items: center;

      @include fontProps("Manrope", 24px, 600, #1a203d, 33px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 20px, 600, #1a203d, 27px);
      }
    }
    .header-loan-amount-return {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      span:first-child {
        @include fontProps("Manrope", 28px, 600, #1a203d, 38px);
        @media only screen and (max-width: 768px) {
          @include fontProps("Manrope", 20px, 600, #1a203d, 27px);
        }
      }
      span:last-child {
        @include fontProps("Manrope", 10px, 600, #b3b3b3, 14px);
      }
    }
  }
  .current-sum-return-wrapper {
    @include fontProps("Manrope", 14px, 600, #1a203d, 19px);
    margin-bottom: 30px;

    span {
      color: #6772e5;
      font-weight: bold;
    }
  }

  .loan-info,
  .loan-info-second {
    margin-top: 66px;
    max-width: 580px;
    display: flex;

    &-item:not(:last-child) {
      margin-right: 20px;
    }

    @media only screen and (max-width: 560px) {
      flex-direction: column;

      &-item:first-child {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }

    @media only screen and (max-width: 768px) {
      order: 2;
      max-width: 100%;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .block-title {
      @include fontProps("Manrope", 16px, 600, #1a203d, 22px);
    }
    .block-content-wrapper {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 10px;
      }

      &.error {
        .MuiInputBase-root {
          border-color: #eb5757;
        }
      }

      .MuiFormControl-root {
        @media only screen and (max-width: 768px) {
          width: 100%;
          &:after,
          &:before {
            content: none;
          }
        }
      }
      .MuiInputBase-root {
        padding: 6px 12px;
        background: rgba(245, 245, 245, 0.3);
        border: 1px solid rgba(200, 200, 200, 0.5);
        box-sizing: border-box;
        border-radius: 8px;

        @media only screen and (min-width: 768px) {
          input {
            max-width: 135px;
          }
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
        }

        &:after,
        &:before {
          content: none;
        }
      }
      .input-amount-wrapper {
        max-width: 232px;
        position: relative;
        @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
        @media only screen and (max-width: 768px) {
          width: 100%;
          max-width: 100%;
        }
        input {
          @include inputStyling();
          padding-right: 30px;
          width: 100%;
        }
        .dolar {
          position: absolute;
          top: 14px;
          right: 10px;
        }
      }
      .loan-block-info-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        @media only screen and (max-width: 768px) {
          margin-left: 0;
          width: 100%;
          margin-top: 0;
          padding: 25px 0;
          &:nth-child(3) {
            border-top: 1px solid #b3b3b3;
          }
        }
        .title {
          @include fontProps("Manrope", 14px, 600, #b3b3b3, 19px);
        }
        .content-wrapper {
          @include fontProps("Manrope", 14px, 600, #1a203d, 19px);
          padding-top: 5px;
          &.balance-wrapper {
            display: flex;
            align-items: center;
            .tick-wrapper {
              border-radius: 50%;
              background: #bce0cb;
              width: 15px;
              height: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 5px;
              img {
                width: 8px;
              }
            }
          }
        }
      }
    }
  }
  .loan-info-second {
    margin-top: 30px;

    @media only screen and (max-width: 560px) {
      margin-top: 0px;
    }

    .loan-info-item {
      width: 100%;

      .block-content-wrapper {
        max-width: 455px;

        @media only screen and (max-width: 768px) {
          max-width: 485px;
        }

        @media only screen and (max-width: 560px) {
          max-width: 100%;
        }

        .input-amount-wrapper {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
  .title-process {
    @include fontProps("Manrope", 14px, 600, #1a203d, 19px);
  }

  .return-money-variants {
    margin-top: 25px;
    max-width: 580px;
    display: flex;
    // justify-content: space-between;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      max-width: 100%;
      margin-top: 0px;
      order: 1;
    }
    .return-variant-wrapper {
      align-items: center;
      border-radius: 8px;
      border: 1px solid #424770;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      max-width: 250px;
      min-height: 227px;
      padding: 16px;
      width: 100%;

      @media only screen and (max-width: 768px) {
        max-width: 100%;
        width: 70%;
        margin-top: 15px;
        background: linear-gradient(90deg, #ececfe, #fdecf6);
        border: none;
      }
      @media only screen and (max-width: 450px) {
        padding: 10px;
        width: 100%;
        margin-right: 0 !important;
      }
      &:not(:last-child) {
        margin-right: 30px;
      }
      .logo-icon-wrapper img {
        width: 100px;
      }
      &.active {
        background: #424770;
        .title {
          color: #ffffff;
        }
        .conditionals-list-wrapper {
          .conditional-block-wrapper {
            .title {
              color: #ffffff;
            }
            .value {
              color: #ffffff;
            }
          }
        }
      }
      &:hover {
        transform: scale(1.04);
        transition: transform 0.2s ease-in-out;
      }
      .title {
        @include fontProps("Manrope", 14px, 600, #424770, 19px);
      }
      .logo-icon-wrapper {
        margin-top: 16px;
      }
      .conditionals-list-wrapper {
        margin-top: 16px;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .conditional-block-wrapper {
          display: flex;
          .title {
            width: 60%;
            @include fontProps("Manrope", 12px, 500, #424770, 16px);
          }
          .value {
            text-align: right;
            width: 40%;
            @include fontProps("Manrope", 12px, 600, #424770, 16px);
          }
          &:not(:first-child) {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .total-payment-block-wrapper {
    margin-top: 49px;
    padding-top: 22px;
    border-top: 1px solid #b3b3b3;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media only screen and (max-width: 768px) {
      order: 3;
      margin-top: 0;
    }
    .title {
      max-width: 300px;
      @include fontProps("Manrope", 16px, 600, #1a203d, 22px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 14px, 600, #1a203d, 19px);
      }
    }
    .value {
      @include fontProps("Manrope", 22px, 600, #000000, 22px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 14px, 600, #000000, 19px);
      }
    }
  }

  .pay-loan-bth-wrapper {
    margin-top: 40px;
    @media only screen and (max-width: 768px) {
      order: 4;
    }
    .pay-loan-bth {
      @include gradientBth(231px);
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow-icon {
        filter: brightness(0) invert(1);
        transform: rotate(180deg);
        margin-left: 10px;
      }
      &.active {
        @include gradiendBgWithHoverEffect();
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
