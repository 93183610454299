@import "../../../../../styles/mixins.scss";
.additional-info-block-wrapper {
  margin: 24px 0;
  border-top: 0.5px solid #e0e0e0;
  .title-wrapper {
    @include fontProps("Manrope", 20px, 600, #404040, 27px);
    margin-top: 24px;
    @media only screen and (max-width: 768px) {
      @include fontProps("Manrope", 18px, 600, #404040, 25px);
    }
  }
  .additional-info-list {
    .additional-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      padding: 4px;
      .title {
        @include fontProps("Manrope", 16px, 600, #404040, 22px);
        @media only screen and (max-width: 768px) {
          @include fontProps("Manrope", 14px, 600, #404040, 19px);
        }
      }
      .value {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;

        @include fontProps("Manrope", 14px, 600, #404040, 19px);
        background: #f9f9f9;
        padding: 5px 16px;
        border-radius: 8px;
        @media only screen and (max-width: 768px) {
          @include fontProps("Manrope", 14px, 600, #404040, 19px);
        }
      }
    }
  }
}
