@import '../../styles/mixins.scss';

.recover-password-page-wrapper {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    #424770;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    max-width: 500px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    .activation-title {
      @include fontProps('Manrope', 25px, 600, #424770, 30px);
      text-align: center;
    }
    .form-content {
      margin-top: 15px;
      margin-bottom: 15px;
      .password-input-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          @include fontProps('Manrope', 16px, 600, #1a203d, 20px);
        }
        input {
          background: rgba(245, 245, 245, 0.3);
          border: 1px solid rgba(200, 200, 200, 0.5);
          padding: 8px;
          border-radius: 4px;
          padding-right: 40px;
          :focus {
            border: 1px solid #000000;
            outline: none;
          }
        }
        .eye-icon__wrapper {
          position: absolute;
          position: absolute;
          right: 10px;
          top: 37px;
          cursor: pointer;
        }
        &:not(:first-child) {
          margin-top: 20px;
        }
        .info-wrapper {
          @include fontProps('Manrope', 14px, bold, red, 19px);
          margin-top: 10px;
        }
      }
      .bth-wrapper {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .bth {
          width: 60%;
          padding: 10px;
          border: none;
          @include fontProps('Manrope', 14px, bold, #404040, 19px);
          @include gradiendBgWithHoverEffect();
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
    .spinner-wrapper {
      margin-top: 30px;
    }
    .bth-wrapper {
      display: flex;
      justify-content: center;
      .bth {
        width: 100%;
        max-width: 246px;
        padding: 13px;
        border: none;
        background: none;
        background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
          #ff7472;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
          0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin-top: 20px;
        @include fontProps('Manrope', 18px, 600, #ffffff, 25px);
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%), #ff7472;
        }
      }
    }
  }
}
