@import '../../../../../../styles/mixins.scss';

.modal-loan-history-item-wrapper {
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  @include scrollBar();
  .MuiPaper-root {
    max-width: 926px;
    width: 100%;
    padding: 40px;
    .content-dialog {
      padding: 5px;
      margin: 10px;
      .close-img {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
      }
      .modal-title {
        @include fontProps('Manrope', 20px, 600, #1a203d, 27px);
      }
      .loan-history-item-table-wrapper {
        margin-top: 23px;
        .loan-history-table-head {
          background: linear-gradient(90deg, #ececfe, #fdecf6);
          .table-head-cell {
            padding: 12px 34px;
            border: none;
            @include fontProps('Manrope', 14px, bold, #424770, 19px);
            &:last-child {
              text-align: right;
            }
          }
        }
        .loan-history-table-body {
          .loan-history-table-body-row {
            .table-body-cell {
              padding: 24px 34px;
              @include fontProps('Manrope', 14px, bold, #424770, 19px);
              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
