@import "../../../../../styles/mixins.scss";

.loans-history-content-mobile-version-wrapper {
  .loans-history-table-mobile-version-wrapper,
  .loans-history-table-item-mobile-version-wrapper {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    .back-bth {
      display: flex;
      align-items: center;
      margin: 16px;
      cursor: pointer;
      .text {
        margin-left: 5px;
        @include fontProps("Manrope", 18px, bold, #424770, 25px);
      }
    }
    .table-wrapper {
      .table-item-wrapper {
        .table-row-wrapper {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          @media only screen and (max-width: 768px) {
            cursor: default;
          }
          &:not(:first-child):not(:last-child) {
            border-bottom: 1px solid rgba(224, 224, 224, 1);
          }

          &.header {
            padding: 9px 16px;
            background: linear-gradient(90deg, #ececfe, #fdecf6);
            .title {
              @include fontProps("Manrope", 14px, bold, #424770, 19px);
              max-width: 300px;
            }
            .value {
              display: flex;
              align-items: center;

              img.tick {
                margin-left: 10px;
                transform: rotate(-180deg);
              }
            }
            .more-bth {
              cursor: pointer;
              &:hover {
                @include hoverEffectScale(1.2);
              }
            }
          }
          .title,
          .value {
            max-width: 160px;
            @include fontProps("Manrope", 14px, normal, #424770, 19px);
          }
        }
      }
    }
  }
}
