@import '../../../../../../../../styles/mixins.scss';

.refuse-loan-wrapper {
  @include scrollBar();
  .MuiPaper-root {
    width: 100%;
    max-width: 668px;
    @media only screen and (max-width: 768px) {
      margin: 16px;
    }
  }

  .MuiDialog-paper {
    @media only screen and (max-width: 768px) {
      margin: 16px;
    }
  }
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .refuse-loan-content {
    padding: 32px !important;
    @media only screen and (max-width: 768px) {
      padding: 30px 16px 16px 16px !important;
    }
    position: relative;
    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        top: 14px;
        right: 14px;
      }
    }
    .main-content-wrapper {
      margin-top: 10px;
      .title {
        text-align: center;
        @include fontProps('Manrope', 18px, 600, #000000, 25px);
      }
      .sub-title {
        @include fontProps('Manrope', 14px, 500, #000000, 24px);
        text-align: center;
        margin-top: 13px;
      }
      .refuse-loan-reasons-wrapper {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .refuse-loan-reason-item {
          border: 1px solid #424770;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 15px 16px;
          width: 100%;
          max-width: 336px;
          cursor: pointer;
          @include fontProps('Manrope', 14px, 600, #424770, 19px);
          &:not(:first-child) {
            margin-top: 16px;
          }
          &.active {
            background: #424770;
            color: #ffffff;
          }
          &:hover {
            transform: scale(1.04);
            transition: transform 0.2s ease-in-out;
          }
        }
      }
      .refuse-loan-bth-wrapper {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        .refuse-loan-bth {
          @include gradientBth(178px);
          @include fontProps('Manrope', 14px, bold, #ffffff, 19px);
          &.active {
            @include gradiendBgWithHoverEffect();
            cursor: pointer;
          }
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
