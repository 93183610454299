@import "../../../../../styles/mixins.scss";

.client-transferences-content-wrapper {
  background: #ffffff;
  flex: 1;
  margin-top: 25px;
  // padding-top: 20px;

  @media screen and(max-width: 550px) {
    padding-left: 20px;
  }

  .main-title {
    padding-left: 50px;
    margin: 20px 0px;
    @include fontProps("Manrope", 17px, bold, #404040, 19px);
  }

  .payment-bths-wrapper {
    margin-top: 0;
  }

  .payment-bths-wrapper .bth-wrapper {
    width: 100%;

    button:disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .bank-title {
    @include fontProps("Manrope", 18px, bold, #404040, 23px);
    margin-bottom: 15px;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }

  .counters {
    display: flex;
    align-items: center;

    .counter {
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
      margin-right: 40px;

      span {
        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }

  .table-head {
    .table-head-cell {
      border-bottom: none;
      padding: 25px 16px;
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:nth-child(2) {
        padding: 25px 0;
      }
    }
  }

  .input-item {
    width: 250px;
    padding: 10px 15px;
    background: rgba(245, 245, 245, 0.3);
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 8px;
    font-family: "Manrope";
    font-weight: 600;
    font-size: 14px;
    color: #1a203d;
    line-height: 20px;

    &.small {
      width: 100px;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .filter-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 40px;
    background-color: #fbf7f8;
    padding-bottom: 20px;

    .react-datepicker__input-container {
      width: auto;
    }

    .react-datepicker__close-icon::after {
      background-color: #eb5757;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-right: 15px;
      @include fontProps("Manrope", 14px, bold, #404040, 19px);

      span {
        margin-bottom: 10px;
      }
    }

    .input-date-container {
      position: relative;

      .input-calendar {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 5px;
      }

      img {
        cursor: pointer;
      }
    }
  }

  .react-datepicker-wrapper {
    width: inherit;
    margin-right: 20%;
  }

  .table-container {
    padding-left: 50px;
  }

  .table-body {
    .empty-data {
      text-align: center;
      margin-top: 10px;
      padding-left: 10px;
      @include fontProps("Manrope", 16px, 500, #404040, 20px);
    }

    .spinner-wrapper .spinner-text {
      font-size: 14px;
    }

    .table-body-row {
      cursor: pointer;
      .table-body-cell {
        padding: 20px 16px;
        @include fontProps("Manrope", 13px, 500, #404040, 18px);
        text-align: center;

        .action-buttons {
          display: flex;
          justify-content: center;
          align-items: center;

          .buttons-container {
            margin-right: 10px;
          }
        }

        .transaction-status-icon {
          display: flex;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
          }

          &.cancel-icon {
            img {
              border-radius: 50%;
              margin-right: 15px;
            }
          }
        }

        &:first-child {
          text-align: left;
        }

        &:nth-child(2) {
          padding: 25px 0;
        }
      }

      &:hover {
        background: #fafafa;
      }
    }
  }
}
