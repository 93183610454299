@import "../../../../../styles/mixins.scss";
.contact-info-block-wrapper {
  margin: 16px 0;
  border-top: 0.5px solid #e0e0e0;
  @media only screen and (max-width: 1096px) {
    border-top: none;
  }

  .title-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    .text {
      @include fontProps("Manrope", 20px, 600, #404040, 27px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 18px, 600, #404040, 25px);
      }
    }
    .change-bth {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      @include fontProps("Manrope", 12px, 500, #404040, 16px);
      .bth-text {
        margin-right: 7px;
      }
      @include hoverEffectScale(1.2);
    }
  }
  .contact-info-list {
    .contact-info-item {
      margin-top: 25px;
      padding: 4px;
      .item-content {
        .title {
          @include fontProps("Manrope", 16px, 600, #404040, 22px);
          width: 40%;
          display: flex;
          justify-content: space-between;
          width: 100%;

          @media only screen and (max-width: 768px) {
            @include fontProps("Manrope", 14px, 600, #404040, 19px);
          }
        }
        .value {
          width: 100%;
          text-align: right;
          padding: 8px;
          margin-top: 10px;
          @include fontProps("Manrope", 16px, 600, #404040, 22px);
          @media only screen and (max-width: 768px) {
            @include fontProps("Manrope", 14px, 600, #404040, 19px);
          }
          &.disabled {
            @include inputDisabled();
          }
          &.active {
            @include inputActive();
          }
          &.personal-phone-value {
            .MuiInputBase-root::before {
              border: none !important;
              @include fontProps("Manrope", 16px, 600, #404040, 22px);
            }

            .MuiInputBase-input {
              padding: 0;
              text-align: right;
              color: #404040;
            }
            .MuiInputAdornment-root {
              display: none;
            }
            &.active {
              .MuiInputAdornment-root {
                display: flex;
              }
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
      .save-new-email-bth-wrapper,
      .verify-new-number-bth-wrapper,
      .save-password-bth-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .bth-border-wrapper {
          max-width: 165px;
          padding: 1.5px;
          width: 100%;
          text-align: center;
          @include bthGradiendBorderWrapper();
          button {
            @include fontProps("Manrope", 14px, 600, #424770, 19px);
            @include bthWithGradiendBorder();
            border-radius: 6px;
            width: 100%;
            padding: 5.5px;
          }
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
      .change-bth {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        @include fontProps("Manrope", 12px, 500, #404040, 16px);
        .bth-text {
          margin-right: 7px;
        }
        @include hoverEffectScale(1.2);
      }
      &.password-info-wrapper {
        .change-bth-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-family: Manrope;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
        .changing-password-inputs-wrapper {
          margin-top: 20px;
          border-top: 0.5px solid #9f9f9f;
          .password-input-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            position: relative;
            label {
              @include fontProps("Manrope", 16px, 600, #404040, 22px);
              width: 40%;
              @media only screen and (max-width: 768px) {
                width: 100%;
              }
            }
            .input-container {
              width: 100%;
              position: relative;

              .eye-icon__wrapper {
                position: absolute;
                right: 10px;
                top: 0px;
                cursor: pointer;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              input {
                @include fontProps("Manrope", 16px, 600, #404040, 22px);
                @include inputActive();
                width: 100%;

                &:focus {
                  outline: none;
                }
              }
            }
            .info-wrapper {
              width: 100%;
              margin-top: 8px;
              @include fontProps("Manrope", 12px, 600, #eb5757, 16px);
            }
            .password-validator-message-wrapper {
              @media only screen and (max-width: 1096px) {
                position: relative;
                left: 0;
                top: 0;
                max-width: 100%;
                margin-top: 20px;
              }
            }
            .password-validator-message-wrapper::before {
              @media only screen and (max-width: 1096px) {
                top: 0;
                left: 0;
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
