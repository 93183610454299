@import "../../../../../styles/mixins.scss";

.address-info-block-wrapper {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04);
  @media only screen and (max-width: 1096px) {
  }
  @media only screen and (max-width: 768px) {
    box-shadow: none;
    padding: 0 16px 16px 16px;
  }
  .title-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      border-top: 0.5px solid #e0e0e0;
      margin-top: 0;
      padding-top: 24px;
      @include fontProps("Manrope", 18px, 600, #404040, 25px);
    }
    .text {
      @include fontProps("Manrope", 20px, 600, #404040, 27px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 18px, 600, #404040, 25px);
      }
    }
    .change-bth {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      @include fontProps("Manrope", 12px, 500, #404040, 16px);
      .bth-text {
        margin-right: 7px;
      }
      @include hoverEffectScale(1.2);
    }
  }
  .address-info-list {
    .address-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      padding: 4px;
      flex-wrap: wrap;
      .title {
        padding: 0 4px;
        @include fontProps("Manrope", 16px, 600, #404040, 22px);
        @media only screen and (max-width: 768px) {
          @include fontProps("Manrope", 14px, 600, #404040, 19px);
        }
      }
      .value {
        padding: 4px;
        word-break: break-all;
        text-align: right;
        @include fontProps("Manrope", 14px, 600, #404040, 19px);
        width: 100%;
        margin-top: 10px;
        padding: 8px;
        &.disabled {
          @include inputDisabled();
        }
        &.active {
          @include inputActive();
        }
        &:focus {
          outline: none;
        }
        @media only screen and (max-width: 768px) {
          @include fontProps("Manrope", 14px, 600, #404040, 19px);
        }
        @media only screen and (max-width: 370px) {
          &.active {
            max-width: 190px;
          }
        }
        @media only screen and (max-width: 320px) {
          &.active {
            max-width: 159px;
          }
        }
      }
      .info-empty {
        width: 100%;
        margin-top: 10px;
        @include fontProps("Manrope", 12px, 600, #eb5757, 16px);
      }
    }
  }

  .save-new-adress-info-bth-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .bth-border-wrapper {
      max-width: 165px;
      padding: 1.5px;
      width: 100%;
      text-align: center;
      @include bthGradiendBorderWrapper();
      button {
        @include fontProps("Manrope", 14px, 600, #424770, 19px);
        @include bthWithGradiendBorder();
        border-radius: 6px;
        width: 100%;
        padding: 5.5px;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .MuiSelect-root {
    &:focus {
      background-color: transparent;
    }
  }

  .MuiInputBase-root {
    &::before,
    &::after {
      border: none !important;
    }
  }
}
