@import '../../../../../../styles//mixins.scss';

.modal-loaner-reason-block-wrapper {
  .MuiPaper-root {
    width: 100%;
    max-width: 560px;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .loaner-reason-block-content {
    padding: 40px !important;
    position: relative;
    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
    }
    .main-content-wrapper {
      margin-top: 10px;
      .textarea-wrapper {
        .textarea-title {
          text-align: center;
          @include fontProps('Manrope', 24px, 600, #404040, 33px);
        }
        textarea {
          width: 100%;
          border: 1px solid #424770;
          border-radius: 8px;
          margin-top: 25px;
          height: 120px;
          padding: 15px;
          resize: none;
          &:focus {
            outline: none;
            border: 1px solid #000000;
          }
        }
      }
      .bth-actions-wrapper {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        .bth-block {
          border: none;
          cursor: pointer;
          width: 100%;
          max-width: 293px;
          padding: 10px;
          background: linear-gradient(90deg, #2b3dff -13.54%, #ff409a 108.96%),
            linear-gradient(90deg, #2b3dff -13.54%, #fa403e 108.96%),
            linear-gradient(90deg, #6772e5 -13.54%, #ff7472 108.96%);
          border-radius: 8px;
          text-align: center;
          @include fontProps('Manrope', 14px, 600, #ffffff, 19px);
          &.disabled {
            opacity: 0.5;
          }
        }
      }
      .text-spinner {
        margin-top: 5px;
        text-align: center;
        animation: blinker 1s linear infinite;
        @include fontProps('Manrope', 12px, bold, #424770, 16px);
      }
      .text-error {
        margin-top: 5px;
        text-align: center;
        @include fontProps('Manrope', 12px, bold, red, 16px);
      }
      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
      @include scrollBar();
    }
  }
}
