@import "../../../../../../styles/mixins.scss";

.bank-details-content-wrapper {
  @include userProfileTabPannelWrapper(
    100%,
    880px,
    100%,
    35px,
    8px,
    rgba(255, 255, 255, 0.5)
  );
  display: flex;
  flex-direction: column;
  .bank-details-block-header {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    .header-decription {
      @include fontProps("Manrope", 20px, 600, #1a203d, 27px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 18px, 600, #1a203d, 25px);
        padding: 24px 0;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        text-align: center;
        width: 100%;
      }
    }
    .header-info-get-money {
      @include fontProps("Manrope", 16px, 600, #1a203d, 24px);
      @media only screen and (max-width: 768px) {
        padding: 14px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .date-info {
        margin-left: 5px;
        border-radius: 8px;
        padding: 6px 14px;
        background: linear-gradient(90deg, #ececfe, #fdecf6);
        @media only screen and (max-width: 768px) {
          margin-top: 10px;
        }
      }
    }
  }
  .errorContainer {
    margin-top: 10px;
    @include fontProps("Manrope", 14px, 600, #eb5757, 24px);
  }
  .loan-data-wrapper {
    margin-top: 40px;
    @media only screen and (max-width: 768px) {
      margin-top: 10px;
    }
    .loan-item-data-wrapper {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        .loan-item-data-title {
          margin-bottom: 10px;
        }
      }

      &:not(:first-child) {
        margin-top: 32px;
        @media only screen and (max-width: 768px) {
          margin-top: 18px;
        }
      }
      .loan-item-data-title {
        @include fontProps("Manrope", 16px, 600, #1a203d, 22px);
        margin-right: 10px;
        width: 170px;
      }

      .radio-group-wrapper {
        display: flex;

        .account-radio-item {
          display: flex;
          align-items: center;

          .input-item {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-top: 0;
          }

          &:first-child {
            margin-right: 12px;
          }

          label {
            margin-bottom: 0;
          }
        }
      }

      .input-wrapper {
        position: relative;
        display: inline-block;
        @media only screen and (max-width: 768px) {
          margin-top: 8px;
          display: block;
          width: 100%;
        }
        .loan-item-data-value {
          @include fontProps("Manrope", 14px, 600, #1a203d, 20px);
          @include inputStyling();
          width: 232px;
          @media only screen and (max-width: 768px) {
            width: 100%;
          }

          &:disabled {
            opacity: 0.7;
          }
          &.active {
            cursor: pointer;
          }
          &.value-swift {
            option {
              cursor: pointer;
              padding: 10px 16px;
              @include fontProps("Manrope", 14px, 600, #8c8f9e, 19px);
            }
          }
        }
        .char-dolar {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          @include fontProps("Manrope", 14px, 600, #8c8f9e, 20px);
        }
      }
    }
  }
  .bth-bank-details-wrapper {
    margin-top: 48px;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
    }
    .bank-details-bth {
      @include gradientBth(231px);
      display: flex;
      align-items: center;
      justify-content: center;

      &.disabled {
        opacity: 0.5;
      }
      &.active {
        @include gradiendBgWithHoverEffect();
      }
      .arrow-icon {
        filter: brightness(0) invert(1);
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }
  }

  .additional-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
    @include fontProps("Manrope", 14px, 600, #8c8f9e, 20px);
    margin-top: 20px;

    @media only screen and (max-width: 768px) {
      flex: 0;
      margin-top: 30px;
      text-align: center;
      line-height: 24px;
    }
  }
}

.bank-account-confirmation {
  margin-top: 20px;
  @include fontProps("Manrope", 14px, normal, #1a203d, 20px);

  span:not(:last-child) {
    margin-right: 5px;
  }

  .highlighted {
    font-weight: bold;
  }
}
