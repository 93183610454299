@import "../../../../styles/mixins.scss";

.upload-photos-container {
  background: linear-gradient(
      90deg,
      rgba(43, 61, 255, 0.4) -13.54%,
      rgba(255, 64, 154, 0.4) 108.96%
    ),
    #424770;
  min-height: 100vh;
  padding-bottom: 10px;

  form {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 80%;
    margin: 20px auto;
    background-color: white;
    border-radius: 8px;

    @media only screen and (max-width: 768px) {
      padding: 15px;
      max-width: 100%;
      margin: 30px;
    }
  }
}
