@import "../../../styles/mixins.scss";

.remaining-balance-slider-wrapper {
  width: 95%;
  margin: auto;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    width: 93%;
  }
  .current-sum-return-wrapper {
    @include fontProps("Manrope", 14px, 600, #1a203d, 19px);
    margin-bottom: 30px;

    span {
      color: #6772e5;
      font-weight: bold;
    }
  }

  .MuiSlider-root {
    .MuiSlider-mark,
    .MuiSlider-markLabel {
      color: #404040;
    }

    @each $index in 1, 3, 5, 7, 9, 11, 13 {
      .MuiSlider-mark[data-index="#{$index}"] {
        display: none;
      }
    }

    @each $index in 1, 3, 5, 7, 9, 11, 13 {
      .MuiSlider-markLabel[data-index="#{$index}"] {
        top: -20px;
      }
    }

    .MuiSlider-thumb {
      display: none;
    }
  }
}
