@import "../../../../../styles/mixins.scss";

.balance-content-wrapper {
  background: #ffffff;
  flex: 1;
  margin-top: 25px;
  padding-left: 50px;
  padding-top: 20px;

  @media screen and(max-width: 550px) {
    padding-left: 20px;
  }

  .filter-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 250px;
    margin-left: 70px;
    margin-bottom: 30px;

    .react-datepicker__close-icon::after {
      background-color: #eb5757;
    }

    .input-date-container {
      position: relative;

      .input-calendar {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 5px;
      }

      img {
        cursor: pointer;
      }
    }

    .input-item {
      width: 100%;
      width: 250px;
      padding: 10px 15px;
      background: rgba(245, 245, 245, 0.3);
      border: 1px solid rgba(200, 200, 200, 0.5);
      border-radius: 8px;
      font-family: "Manrope";
      font-weight: 600;
      font-size: 14px;
      color: #1a203d;
      line-height: 20px;

      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  .payment-operators-container {
    display: flex;

    .title-section {
      @include fontProps("Manrope", 16px, bold, #404040, 23px);
      margin-bottom: 25px;
    }

    .action-button-container {
      .bth-wrapper.make-payment-bth-wrapper {
        width: 100%;
        margin-top: 20px;
      }

      button:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }
    }

    .selection-file-container {
      margin-right: 100px;
      margin-left: 100px;

      .warning-message {
        max-width: 280px;
        text-align: center;

        span {
          color: #3a6b97;

          &:first-child {
            color: #eb5757;
            margin-right: 5px;
          }
        }
      }

      .file-container {
        .file-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .text {
            margin: 10px 0;
          }

          .remove-file-button {
            background: transparent;
            border: none;

            img {
              width: 20px;
            }
          }

          .file-name {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include fontProps("Manrope", 16px, bold, #3a6b97, 23px);
          }

          label {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            border: 2px solid #3a6b97;
            border-radius: 10px;
            cursor: pointer;

            @include fontProps("Manrope", 14px, normal, #3a6b97, 23px);

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .operators-list {
      display: flex;

      .operator-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border: 2px solid #3a6b97;
        padding: 8px;
        border-radius: 10px;

        &.active {
          background-color: #cccccca1;
        }

        &:not(:last-child) {
          margin-right: 12px;
        }

        img {
          width: 100px;
          // height: 40px;
        }
      }
    }
  }

  .bank-info-wrapper {
    margin: 60px 0;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }

  .counters {
    display: flex;
    align-items: center;

    .counter {
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
      margin-right: 40px;

      span {
        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .payment-bths-wrapper {
      margin-top: 0;
    }

    .payment-bths-wrapper .bth-wrapper {
      width: 100%;

      button:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }

  .table-head {
    .table-head-cell {
      border-bottom: none;
      padding: 25px 16px;
      @include fontProps("Manrope", 14px, bold, #404040, 19px);
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:nth-child(2) {
        padding: 25px 0;
      }
    }
  }

  .table-body {
    .empty-data {
      margin-top: 10px;
      padding-left: 10px;
      @include fontProps("Manrope", 14px, 500, #404040, 20px);
    }

    .table-body-row {
      cursor: pointer;
      .table-body-cell {
        padding: 20px 16px;
        @include fontProps("Manrope", 13px, 500, #404040, 18px);
        text-align: center;

        .transaction-status-icon {
          display: flex;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
          }

          &.cancel-icon {
            img {
              border-radius: 50%;
              margin-right: 15px;
            }
          }
        }

        &:first-child {
          text-align: left;
        }

        &:nth-child(2) {
          padding: 25px 0;
        }
      }

      &:hover {
        background: #fafafa;
      }
    }
  }
}
