@import "../../../../../../../styles//mixins.scss";

.loan-status-wrapper {
  .status-indicator-wrapper.overdue-loan {
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    margin-top: 10px;
    background: transparent;

    span.dot {
      display: block;
      margin-top: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #eb5757;
    }

    span:last-child {
      @include fontProps("Manrope", 14px, 500, #eb5757, 22px);
      margin-left: 12px;
      width: 98%;
    }
  }
}
