@import "../../../../../../../styles//mixins.scss";

.modal-modify-limit-wrapper {
  .spinner-wrapper,
  .error-wrapper {
    margin-top: 50px;
  }

  .textarea-wrapper {
    .textarea-title {
      text-align: center;
      @include fontProps("Manrope", 24px, 600, #404040, 33px);
    }
    textarea {
      width: 100%;
      border: 1px solid #424770;
      border-radius: 8px;
      height: 120px;
      padding: 15px;
      resize: none;
      &:focus {
        outline: none;
        border: 1px solid #000000;
      }
    }
  }

  .MuiPaper-root {
    width: 100%;
    max-width: 700px;
  }
  .MuiDialog-paper {
    margin: 10px;
  }
  .MuiBackdrop-root {
    background: #424770;
    opacity: 0.5 !important;
  }
  .modify-limit-content {
    padding: 0 0 40px 0 !important;
    @media screen and(max-width: 720px) {
      padding: 0 0 20px 0 !important;
    }
    .modify-limit-header-wrapper {
      padding: 22px 42px;
      background: linear-gradient(90deg, #ececfe, #fdecf6);
      display: flex;
      justify-content: space-between;
      @media screen and(max-width: 720px) {
        padding: 10px 20px;
        flex-wrap: wrap;
      }

      .contract-id {
        @include fontProps("Manrope", 20px, 600, #404040, 27px);
        @media screen and(max-width: 720px) {
          width: 100%;
          order: 1;
          margin-top: 15px;
        }
      }
      .user-name-wrapper {
        display: flex;
        align-items: center;
        @media screen and(max-width: 720px) {
          max-width: 80%;
          align-items: flex-start;
        }
        .user-name {
          @include fontProps("Manrope", 16px, 600, #404040, 22px);
        }
        .user-name-icon-wrapper {
          margin-left: 15px;
        }
      }
    }

    .buttons-container {
      display: flex;
      width: 70%;
      justify-content: center;
      margin: auto;

      button:disabled {
        opacity: 0.3;
      }

      .dismiss-bths-wrapper,
      .payment-bths-wrapper {
        width: 100%;

        padding: 0 10px;
      }

      .bth-wrapper {
        width: 100%;
      }
    }

    .main-content-wrapper {
      padding: 0 42px;
      margin-top: 50px;
      @media screen and(max-width: 720px) {
        padding: 0 20px;
        margin-top: 30px;
      }

      .change-limit-title {
        text-align: center;
        @include fontProps("Manrope", 18px, 600, #404040, 22px);
      }

      .change-limit-subtitle {
        text-align: center;
        margin: 15px 0;
        @include fontProps("Manrope", 14px, 600, #404040, 22px);
      }

      .radio-buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        .radio-title {
          padding: 0 10px;
          @include fontProps("Manrope", 16px, 600, #000000, 22px);
          .start {
            color: red;
          }
        }
        .radio-group-wrapper {
          display: flex;
          flex-direction: column;

          @media only screen and (max-width: 400px) {
            max-width: 100%;
            margin-top: 10px;
          }
          .radio-group-item-wrapper {
            display: flex;
            align-items: center;

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            label {
              margin: 0;
              @include fontProps("Manrope", 14px, 600, #404040, 22px);
            }

            .input-item {
              width: 20px;
              height: 20px;
              margin-right: 5px;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  @include scrollBar();
}
