.modal-img-wrapper {
  .MuiPaper-root {
    width: 100%;
    max-width: fit-content;
  }
  .MuiBackdrop-root {
    background: linear-gradient(to right, #6968b5, #9d689c);
    opacity: 0.9 !important;
  }
  .modal-img-content {
    max-width: 800px;
    height: 100vh;
    padding: 40px !important;
    position: relative;
    display: flex;
    justify-content: center;

    .arrow-button {
      cursor: pointer;
      align-items: center;
      background: white;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      left: 100px;
      position: fixed;
      top: 50vh;
      width: 40px;

      img {
        width: 15px;
      }

      &.rigth-button {
        left: inherit;
        right: 100px;

        img {
          transform: rotate(180deg);
        }
      }
    }

    .close-icon-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    .modal-img {
      margin: 20px auto 0 auto;
      display: block;
      max-width: 100%;
      max-height: 100vh;
    }
  }
}
