@import "../../../../styles/mixins.scss";

.promocode-tab-wrapper {
  @include userProfileTabPannelWrapper(
    100%,
    650px,
    100%,
    35px,
    8px,
    rgba(255, 255, 255, 0.5)
  );
  @media only screen and (max-width: 768px) {
    padding: 0 0 16px 0;
  }
  .tab-panel-title-wrapper {
    @include fontProps("Manrope", 20px, 600, #424770, 27px);
    @media only screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
      @include fontProps("Manrope", 20px, 600, #424770, 27px);
      padding: 0 16px;
      span {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        display: block;
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
  }
  .your-promocode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;

    @media only screen and (max-width: 940px) {
      flex-direction: column;
      padding: 0 16px;
    }

    @media only screen and (max-width: 768px) {
      margin: 0;
    }

    .text {
      @include fontProps("Manrope", 20px, 600, #424770, 27px);
      margin-right: 10px;

      @media only screen and (max-width: 940px) {
        margin: 15px 0;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;

        margin-right: 0px;
        padding-top: 32px;
        @include fontProps("Manrope", 16px, 600, #424770, 22px);
      }
    }
    .promocode-content-wrapper {
      display: flex;
      margin-top: 16px;
      @media only screen and (max-width: 768px) {
        justify-content: space-between;
        width: 100%;
      }
      .promocode-value {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        border: 1px solid;
        padding: 13px 34px;
        @include fontProps("Manrope", 15px, bold, #e0e0e0, 20px);
        @media only screen and (max-width: 768px) {
          width: 45%;
          text-align: center;
          padding: 13px;
        }
      }
      .bth-copy-wrapper {
        position: relative;
        display: flex;
        @media only screen and (max-width: 768px) {
          width: 45%;
        }
        .bth-copy {
          cursor: pointer;
          border: none;
          @include fontProps("Manrope", 15px, bold, #ffffff, 20px);
          text-transform: uppercase;
          padding: 13px 34px;
          margin-left: 10px;
          @include gradiendBgWithHoverEffect();
          height: auto;
          @media only screen and (max-width: 768px) {
            margin-left: 0;
            width: 100%;
            text-align: center;
            padding: 13px;
          }
        }
        .copy-info {
          position: absolute;
          bottom: -20px;
          right: 0;
          @include fontProps("Manrope", 14px, 500, #219653, 20px);
        }
      }
    }
  }
  .instruction-wrapper {
    margin-top: 32px;
    padding-top: 32px;
    padding-bottom: 12px;
    border-top: 0.5px solid #e0e0e0;
    border-bottom: 0.5px solid #e0e0e0;
    @media only screen and (max-width: 768px) {
      padding: 32px 16px 12px 16px;
    }
    .title {
      @include fontProps("Manrope", 20px, 600, #424770, 27px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 16px, 600, #424770, 22px);
      }
    }
    .main-text {
      margin-top: 12px;
      @include fontProps("Manrope", 18px, 600, #424770, 28px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 16px, normal, #424770, 28px);
      }
    }
  }
  .promocode-history-wrapper {
    margin-top: 30px;
    .title {
      @include fontProps("Manrope", 20px, 600, #424770, 27px);
      @media only screen and (max-width: 768px) {
        @include fontProps("Manrope", 16px, 600, #424770, 22px);
        padding: 0 16px;
      }
    }
    .promocodes-table-wrapper {
      margin-top: 24px;
      .header {
        display: flex;
        justify-content: space-between;
        background: linear-gradient(90deg, #ececfe, #fdecf6);
        border: none;
        padding: 11px 16px;

        .title {
          @include fontProps("Manrope", 14px, bold, #424770, 19px);
          width: 33.3%;
          text-align: center;

          &.full {
            width: 100%;
            text-align: left;
          }
        }

        &:last-child {
          text-align: right;
        }
      }
      .promocode-item {
        display: flex;
        justify-content: space-between;
        padding: 11px 16px;

        @media only screen and (max-width: 768px) {
          padding: 11px 32px;
        }
        .value {
          @include fontProps("Manrope", 14px, 600, #424770, 19px);
          width: 33.3%;
          text-align: center;

          &.full {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
}
