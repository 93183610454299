@import "../../../../../../../styles//mixins.scss";

.loan-status-wrapper {
  .status-indicator-wrapper.active-loan {
    display: flex;
    align-items: center;
    width: 100%;
    @include statusIndicatorWrapper(#f6fbf8);
    span:first-child {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #219653;
    }
    span:last-child {
      @include fontProps("Manrope", 16px, bold, #1a203d, 22px);
      margin-left: 12px;
    }
  }

  .status-indicator-wrapper.refinanced-loan {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 12px;

    // @include statusIndicatorWrapper(#f6fbf8);

    span {
      @include fontProps("Manrope", 14px, 500, #eb5757, 22px);
      margin-left: 12px;
      width: 98%;
    }
  }
}
